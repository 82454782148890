/*!
 * Tabler Icons 1.39.1 by tabler - https://tabler.io
 * License - https://github.com/tabler/tabler-icons/blob/master/LICENSE
 */
$ti-font-family: "tabler-icons" !default;
$ti-font-path: "../../fonts" !default;
$ti-font-display: null !default;
$ti-icon-prefix: "ti" !default;

@font-face {
    font-family: $ti-font-family;
    font-style: normal;
    font-weight: 400;
    font-display: $ti-font-display;
    src: url("#{$ti-font-path}/tabler-icons.eot");
    src: url("#{$ti-font-path}/tabler-icons.eot?#iefix")
            format("embedded-opentype"),
        url("#{$ti-font-path}/tabler-icons.woff2") format("woff2"),
        url("#{$ti-font-path}/tabler-icons.woff") format("woff"),
        url("#{$ti-font-path}/tabler-icons.ttf") format("truetype"),
        url("#{$ti-font-path}/tabler-icons.svg\##{$ti-font-family}")
            format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: $ti-font-family;
        src: url("#{$ti-font-path}/tabler-icons.svg\##{$ti-font-family}")
            format("svg");
    }
}

.#{$ti-icon-prefix} {
    font-family: $ti-font-family !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$ti-icon-2fa: "\eca0";
$ti-icon-3d-cube-sphere: "\ecd7";
$ti-icon-a-b: "\ec36";
$ti-icon-access-point: "\ed1b";
$ti-icon-access-point-off: "\ed1a";
$ti-icon-accessible: "\eba9";
$ti-icon-activity: "\ed23";
$ti-icon-ad: "\ea02";
$ti-icon-adjustments: "\ea03";
$ti-icon-adjustments-alt: "\ec37";
$ti-icon-adjustments-horizontal: "\ec38";
$ti-icon-aerial-lift: "\edfe";
$ti-icon-affiliate: "\edff";
$ti-icon-alarm: "\ea04";
$ti-icon-alert-circle: "\ea05";
$ti-icon-alert-octagon: "\ecc6";
$ti-icon-alert-triangle: "\ea06";
$ti-icon-alien: "\ebde";
$ti-icon-align-center: "\ea07";
$ti-icon-align-justified: "\ea08";
$ti-icon-align-left: "\ea09";
$ti-icon-align-right: "\ea0a";
$ti-icon-ambulance: "\ebf5";
$ti-icon-anchor: "\eb76";
$ti-icon-antenna-bars-1: "\ecc7";
$ti-icon-antenna-bars-2: "\ecc8";
$ti-icon-antenna-bars-3: "\ecc9";
$ti-icon-antenna-bars-4: "\ecca";
$ti-icon-antenna-bars-5: "\eccb";
$ti-icon-aperture: "\eb58";
$ti-icon-apps: "\ebb6";
$ti-icon-archive: "\ea0b";
$ti-icon-arrow-back: "\ea0c";
$ti-icon-arrow-back-up: "\eb77";
$ti-icon-arrow-bar-down: "\ea0d";
$ti-icon-arrow-bar-left: "\ea0e";
$ti-icon-arrow-bar-right: "\ea0f";
$ti-icon-arrow-bar-to-down: "\ec88";
$ti-icon-arrow-bar-to-left: "\ec89";
$ti-icon-arrow-bar-to-right: "\ec8a";
$ti-icon-arrow-bar-to-up: "\ec8b";
$ti-icon-arrow-bar-up: "\ea10";
$ti-icon-arrow-big-down: "\edda";
$ti-icon-arrow-big-left: "\eddb";
$ti-icon-arrow-big-right: "\eddc";
$ti-icon-arrow-big-top: "\eddd";
$ti-icon-arrow-bottom-bar: "\ed98";
$ti-icon-arrow-bottom-circle: "\ed99";
$ti-icon-arrow-bottom-square: "\ed9a";
$ti-icon-arrow-bottom-tail: "\ed9b";
$ti-icon-arrow-down: "\ea16";
$ti-icon-arrow-down-circle: "\ea11";
$ti-icon-arrow-down-left: "\ea13";
$ti-icon-arrow-down-left-circle: "\ea12";
$ti-icon-arrow-down-right: "\ea15";
$ti-icon-arrow-down-right-circle: "\ea14";
$ti-icon-arrow-forward: "\ea17";
$ti-icon-arrow-forward-up: "\eb78";
$ti-icon-arrow-left: "\ea19";
$ti-icon-arrow-left-bar: "\ed9c";
$ti-icon-arrow-left-circle: "\ea18";
$ti-icon-arrow-left-square: "\ed9d";
$ti-icon-arrow-left-tail: "\ed9e";
$ti-icon-arrow-loop-left: "\ed9f";
$ti-icon-arrow-loop-right: "\eda0";
$ti-icon-arrow-narrow-down: "\ea1a";
$ti-icon-arrow-narrow-left: "\ea1b";
$ti-icon-arrow-narrow-right: "\ea1c";
$ti-icon-arrow-narrow-up: "\ea1d";
$ti-icon-arrow-ramp-left: "\ed3c";
$ti-icon-arrow-ramp-right: "\ed3d";
$ti-icon-arrow-right: "\ea1f";
$ti-icon-arrow-right-bar: "\eda1";
$ti-icon-arrow-right-circle: "\ea1e";
$ti-icon-arrow-right-square: "\eda2";
$ti-icon-arrow-right-tail: "\eda3";
$ti-icon-arrow-top-bar: "\eda4";
$ti-icon-arrow-top-circle: "\eda5";
$ti-icon-arrow-top-square: "\eda6";
$ti-icon-arrow-top-tail: "\eda7";
$ti-icon-arrow-up: "\ea25";
$ti-icon-arrow-up-circle: "\ea20";
$ti-icon-arrow-up-left: "\ea22";
$ti-icon-arrow-up-left-circle: "\ea21";
$ti-icon-arrow-up-right: "\ea24";
$ti-icon-arrow-up-right-circle: "\ea23";
$ti-icon-arrow-wave-left-down: "\eda8";
$ti-icon-arrow-wave-left-up: "\eda9";
$ti-icon-arrow-wave-right-down: "\edaa";
$ti-icon-arrow-wave-right-up: "\edab";
$ti-icon-arrows-diagonal: "\ea27";
$ti-icon-arrows-diagonal-2: "\ea26";
$ti-icon-arrows-double-ne-sw: "\edde";
$ti-icon-arrows-double-nw-se: "\eddf";
$ti-icon-arrows-double-se-nw: "\ede0";
$ti-icon-arrows-double-sw-ne: "\ede1";
$ti-icon-arrows-down: "\edad";
$ti-icon-arrows-down-up: "\edac";
$ti-icon-arrows-horizontal: "\eb59";
$ti-icon-arrows-join: "\edaf";
$ti-icon-arrows-join-2: "\edae";
$ti-icon-arrows-left: "\edb1";
$ti-icon-arrows-left-down: "\ee00";
$ti-icon-arrows-left-right: "\edb0";
$ti-icon-arrows-maximize: "\ea28";
$ti-icon-arrows-minimize: "\ea29";
$ti-icon-arrows-right: "\edb3";
$ti-icon-arrows-right-down: "\ee01";
$ti-icon-arrows-right-left: "\edb2";
$ti-icon-arrows-sort: "\eb5a";
$ti-icon-arrows-split: "\edb5";
$ti-icon-arrows-split-2: "\edb4";
$ti-icon-arrows-up: "\edb7";
$ti-icon-arrows-up-down: "\edb6";
$ti-icon-arrows-up-left: "\ee02";
$ti-icon-arrows-up-right: "\ee03";
$ti-icon-arrows-vertical: "\eb5b";
$ti-icon-artboard: "\ea2a";
$ti-icon-aspect-ratio: "\ed30";
$ti-icon-at: "\ea2b";
$ti-icon-atom: "\eb79";
$ti-icon-atom-2: "\ebdf";
$ti-icon-award: "\ea2c";
$ti-icon-backhoe: "\ed86";
$ti-icon-backspace: "\ea2d";
$ti-icon-ball-american-football: "\ee04";
$ti-icon-ball-basketball: "\ec28";
$ti-icon-ball-bowling: "\ec29";
$ti-icon-ball-football: "\ee06";
$ti-icon-ball-football-off: "\ee05";
$ti-icon-ball-tennis: "\ec2a";
$ti-icon-ball-volleyball: "\ec2b";
$ti-icon-ban: "\ea2e";
$ti-icon-bandage: "\eb7a";
$ti-icon-barcode: "\ebc6";
$ti-icon-basket: "\ebe1";
$ti-icon-battery: "\ea34";
$ti-icon-battery-1: "\ea2f";
$ti-icon-battery-2: "\ea30";
$ti-icon-battery-3: "\ea31";
$ti-icon-battery-4: "\ea32";
$ti-icon-battery-automotive: "\ee07";
$ti-icon-battery-charging: "\ea33";
$ti-icon-battery-off: "\ed1c";
$ti-icon-bed: "\eb5c";
$ti-icon-bell: "\ea35";
$ti-icon-bell-minus: "\ede2";
$ti-icon-bell-off: "\ece9";
$ti-icon-bell-plus: "\ede3";
$ti-icon-bell-ringing: "\ed07";
$ti-icon-bell-ringing-2: "\ede4";
$ti-icon-bell-x: "\ede5";
$ti-icon-bike: "\ea36";
$ti-icon-binary: "\ee08";
$ti-icon-biohazard: "\ecb8";
$ti-icon-blockquote: "\ee09";
$ti-icon-bluetooth: "\ea37";
$ti-icon-bluetooth-connected: "\ecea";
$ti-icon-bluetooth-off: "\eceb";
$ti-icon-bold: "\eb7b";
$ti-icon-bolt: "\ea38";
$ti-icon-bolt-off: "\ecec";
$ti-icon-bone: "\edb8";
$ti-icon-book: "\ea39";
$ti-icon-bookmark: "\ea3a";
$ti-icon-bookmark-off: "\eced";
$ti-icon-bookmarks: "\ed08";
$ti-icon-border-all: "\ea3b";
$ti-icon-border-bottom: "\ea3c";
$ti-icon-border-horizontal: "\ea3d";
$ti-icon-border-inner: "\ea3e";
$ti-icon-border-left: "\ea3f";
$ti-icon-border-none: "\ea40";
$ti-icon-border-outer: "\ea41";
$ti-icon-border-radius: "\eb7c";
$ti-icon-border-right: "\ea42";
$ti-icon-border-style: "\ee0a";
$ti-icon-border-top: "\ea43";
$ti-icon-border-vertical: "\ea44";
$ti-icon-box: "\ea45";
$ti-icon-box-margin: "\ee0b";
$ti-icon-box-model: "\ee0c";
$ti-icon-box-multiple: "\ee17";
$ti-icon-box-multiple-0: "\ee0d";
$ti-icon-box-multiple-1: "\ee0e";
$ti-icon-box-multiple-2: "\ee0f";
$ti-icon-box-multiple-3: "\ee10";
$ti-icon-box-multiple-4: "\ee11";
$ti-icon-box-multiple-5: "\ee12";
$ti-icon-box-multiple-6: "\ee13";
$ti-icon-box-multiple-7: "\ee14";
$ti-icon-box-multiple-8: "\ee15";
$ti-icon-box-multiple-9: "\ee16";
$ti-icon-box-padding: "\ee18";
$ti-icon-braces: "\ebcc";
$ti-icon-brackets: "\ebcd";
$ti-icon-brand-airbnb: "\ed68";
$ti-icon-brand-android: "\ec16";
$ti-icon-brand-apple: "\ec17";
$ti-icon-brand-apple-arcade: "\ed69";
$ti-icon-brand-appstore: "\ed24";
$ti-icon-brand-asana: "\edc5";
$ti-icon-brand-behance: "\ec6e";
$ti-icon-brand-bing: "\edc6";
$ti-icon-brand-bitbucket: "\edc7";
$ti-icon-brand-booking: "\edc8";
$ti-icon-brand-chrome: "\ec18";
$ti-icon-brand-codepen: "\ec6f";
$ti-icon-brand-codesandbox: "\ed6a";
$ti-icon-brand-css3: "\ed6b";
$ti-icon-brand-deviantart: "\ecfb";
$ti-icon-brand-discord: "\ece3";
$ti-icon-brand-disqus: "\edc9";
$ti-icon-brand-docker: "\edca";
$ti-icon-brand-dribbble: "\ec19";
$ti-icon-brand-edge: "\ecfc";
$ti-icon-brand-facebook: "\ec1a";
$ti-icon-brand-figma: "\ec93";
$ti-icon-brand-firefox: "\ecfd";
$ti-icon-brand-flickr: "\ecfe";
$ti-icon-brand-foursquare: "\ecff";
$ti-icon-brand-framer: "\ec1b";
$ti-icon-brand-github: "\ec1c";
$ti-icon-brand-gitlab: "\ec1d";
$ti-icon-brand-google: "\ec1f";
$ti-icon-brand-google-analytics: "\edcb";
$ti-icon-brand-google-drive: "\ec1e";
$ti-icon-brand-google-play: "\ed25";
$ti-icon-brand-gravatar: "\edcc";
$ti-icon-brand-hipchat: "\edcd";
$ti-icon-brand-html5: "\ed6c";
$ti-icon-brand-instagram: "\ec20";
$ti-icon-brand-kickstarter: "\edce";
$ti-icon-brand-kotlin: "\ed6d";
$ti-icon-brand-linkedin: "\ec8c";
$ti-icon-brand-medium: "\ec70";
$ti-icon-brand-messenger: "\ec71";
$ti-icon-brand-netflix: "\edcf";
$ti-icon-brand-open-source: "\edd0";
$ti-icon-brand-opera: "\ec21";
$ti-icon-brand-pagekit: "\edd1";
$ti-icon-brand-patreon: "\edd2";
$ti-icon-brand-paypal: "\ec22";
$ti-icon-brand-pinterest: "\ec8d";
$ti-icon-brand-pocket: "\ed00";
$ti-icon-brand-producthunt: "\edd3";
$ti-icon-brand-python: "\ed01";
$ti-icon-brand-reddit: "\ec8e";
$ti-icon-brand-safari: "\ec23";
$ti-icon-brand-sass: "\edd4";
$ti-icon-brand-sentry: "\edd5";
$ti-icon-brand-shazam: "\edd6";
$ti-icon-brand-sketch: "\ec24";
$ti-icon-brand-skype: "\ed02";
$ti-icon-brand-slack: "\ec72";
$ti-icon-brand-snapchat: "\ec25";
$ti-icon-brand-soundcloud: "\ed6e";
$ti-icon-brand-spotify: "\ed03";
$ti-icon-brand-steam: "\ed6f";
$ti-icon-brand-stripe: "\edd7";
$ti-icon-brand-tabler: "\ec8f";
$ti-icon-brand-tailwind: "\eca1";
$ti-icon-brand-telegram: "\ec26";
$ti-icon-brand-tidal: "\ed70";
$ti-icon-brand-tiktok: "\ec73";
$ti-icon-brand-tinder: "\ed71";
$ti-icon-brand-tumblr: "\ed04";
$ti-icon-brand-twitch: "\ed05";
$ti-icon-brand-twitter: "\ec27";
$ti-icon-brand-unsplash: "\edd8";
$ti-icon-brand-vimeo: "\ed06";
$ti-icon-brand-vk: "\ed72";
$ti-icon-brand-whatsapp: "\ec74";
$ti-icon-brand-windows: "\ecd8";
$ti-icon-brand-yahoo: "\ed73";
$ti-icon-brand-ycombinator: "\edd9";
$ti-icon-brand-youtube: "\ec90";
$ti-icon-briefcase: "\ea46";
$ti-icon-brightness: "\eb7f";
$ti-icon-brightness-2: "\ee19";
$ti-icon-brightness-down: "\eb7d";
$ti-icon-brightness-half: "\ee1a";
$ti-icon-brightness-up: "\eb7e";
$ti-icon-browser: "\ebb7";
$ti-icon-brush: "\ebb8";
$ti-icon-bucket: "\ea47";
$ti-icon-bug: "\ea48";
$ti-icon-building: "\ea4f";
$ti-icon-building-arch: "\ea49";
$ti-icon-building-bank: "\ebe2";
$ti-icon-building-bridge: "\ea4b";
$ti-icon-building-bridge-2: "\ea4a";
$ti-icon-building-carousel: "\ed87";
$ti-icon-building-castle: "\ed88";
$ti-icon-building-church: "\ea4c";
$ti-icon-building-community: "\ebf6";
$ti-icon-building-cottage: "\ee1b";
$ti-icon-building-factory: "\ee1c";
$ti-icon-building-fortress: "\ed89";
$ti-icon-building-hospital: "\ea4d";
$ti-icon-building-lighthouse: "\ed8a";
$ti-icon-building-monument: "\ed26";
$ti-icon-building-pavilon: "\ebf7";
$ti-icon-building-skyscraper: "\ec39";
$ti-icon-building-store: "\ea4e";
$ti-icon-building-warehouse: "\ebe3";
$ti-icon-bulb: "\ea51";
$ti-icon-bulb-off: "\ea50";
$ti-icon-bulldozer: "\ee1d";
$ti-icon-bus: "\ebe4";
$ti-icon-businessplan: "\ee1e";
$ti-icon-calculator: "\eb80";
$ti-icon-calendar: "\ea53";
$ti-icon-calendar-event: "\ea52";
$ti-icon-calendar-minus: "\ebb9";
$ti-icon-calendar-off: "\ee1f";
$ti-icon-calendar-plus: "\ebba";
$ti-icon-calendar-stats: "\ee20";
$ti-icon-calendar-time: "\ee21";
$ti-icon-camera: "\ea54";
$ti-icon-camera-minus: "\ec3a";
$ti-icon-camera-off: "\ecee";
$ti-icon-camera-plus: "\ec3b";
$ti-icon-camera-rotate: "\ee22";
$ti-icon-camera-selfie: "\ee23";
$ti-icon-capture: "\ec3c";
$ti-icon-car: "\ebbb";
$ti-icon-caravan: "\ec7c";
$ti-icon-cardboards: "\ed74";
$ti-icon-caret-down: "\eb5d";
$ti-icon-caret-left: "\eb5e";
$ti-icon-caret-right: "\eb5f";
$ti-icon-caret-up: "\eb60";
$ti-icon-cash: "\ea55";
$ti-icon-cash-banknote: "\ee25";
$ti-icon-cash-banknote-off: "\ee24";
$ti-icon-cast: "\ea56";
$ti-icon-ce: "\ed75";
$ti-icon-certificate: "\ed76";
$ti-icon-charging-pile: "\ee26";
$ti-icon-chart-arcs: "\ee28";
$ti-icon-chart-arcs-3: "\ee27";
$ti-icon-chart-area: "\ea58";
$ti-icon-chart-area-line: "\ea57";
$ti-icon-chart-arrows: "\ee2a";
$ti-icon-chart-arrows-vertical: "\ee29";
$ti-icon-chart-bar: "\ea59";
$ti-icon-chart-bubble: "\ec75";
$ti-icon-chart-candle: "\ea5a";
$ti-icon-chart-circles: "\ee2b";
$ti-icon-chart-donut: "\ea5b";
$ti-icon-chart-donut-2: "\ee2c";
$ti-icon-chart-donut-3: "\ee2d";
$ti-icon-chart-donut-4: "\ee2e";
$ti-icon-chart-dots: "\ee2f";
$ti-icon-chart-infographic: "\ee30";
$ti-icon-chart-line: "\ea5c";
$ti-icon-chart-pie: "\ea5d";
$ti-icon-chart-pie-2: "\ee31";
$ti-icon-chart-pie-3: "\ee32";
$ti-icon-chart-pie-4: "\ee33";
$ti-icon-chart-radar: "\ed77";
$ti-icon-check: "\ea5e";
$ti-icon-checkbox: "\eba6";
$ti-icon-checks: "\ebaa";
$ti-icon-chevron-down: "\ea5f";
$ti-icon-chevron-down-left: "\ed09";
$ti-icon-chevron-down-right: "\ed0a";
$ti-icon-chevron-left: "\ea60";
$ti-icon-chevron-right: "\ea61";
$ti-icon-chevron-up: "\ea62";
$ti-icon-chevron-up-left: "\ed0b";
$ti-icon-chevron-up-right: "\ed0c";
$ti-icon-chevrons-down: "\ea63";
$ti-icon-chevrons-down-left: "\ed0d";
$ti-icon-chevrons-down-right: "\ed0e";
$ti-icon-chevrons-left: "\ea64";
$ti-icon-chevrons-right: "\ea65";
$ti-icon-chevrons-up: "\ea66";
$ti-icon-chevrons-up-left: "\ed0f";
$ti-icon-chevrons-up-right: "\ed10";
$ti-icon-christmas-tree: "\ed78";
$ti-icon-circle: "\ea6b";
$ti-icon-circle-0: "\ee34";
$ti-icon-circle-1: "\ee35";
$ti-icon-circle-2: "\ee36";
$ti-icon-circle-3: "\ee37";
$ti-icon-circle-4: "\ee38";
$ti-icon-circle-5: "\ee39";
$ti-icon-circle-6: "\ee3a";
$ti-icon-circle-7: "\ee3b";
$ti-icon-circle-8: "\ee3c";
$ti-icon-circle-9: "\ee3d";
$ti-icon-circle-check: "\ea67";
$ti-icon-circle-dashed: "\ed27";
$ti-icon-circle-dotted: "\ed28";
$ti-icon-circle-half: "\ee3f";
$ti-icon-circle-half-vertical: "\ee3e";
$ti-icon-circle-minus: "\ea68";
$ti-icon-circle-off: "\ee40";
$ti-icon-circle-plus: "\ea69";
$ti-icon-circle-square: "\ece4";
$ti-icon-circle-x: "\ea6a";
$ti-icon-circles: "\ece5";
$ti-icon-clear-all: "\ee41";
$ti-icon-clear-formatting: "\ebe5";
$ti-icon-click: "\ebbc";
$ti-icon-clipboard: "\ea6f";
$ti-icon-clipboard-check: "\ea6c";
$ti-icon-clipboard-list: "\ea6d";
$ti-icon-clipboard-x: "\ea6e";
$ti-icon-clock: "\ea70";
$ti-icon-cloud: "\ea76";
$ti-icon-cloud-download: "\ea71";
$ti-icon-cloud-fog: "\ecd9";
$ti-icon-cloud-off: "\ed3e";
$ti-icon-cloud-rain: "\ea72";
$ti-icon-cloud-snow: "\ea73";
$ti-icon-cloud-storm: "\ea74";
$ti-icon-cloud-upload: "\ea75";
$ti-icon-code: "\ea77";
$ti-icon-code-minus: "\ee42";
$ti-icon-code-plus: "\ee43";
$ti-icon-coin: "\eb82";
$ti-icon-color-picker: "\ebe6";
$ti-icon-color-swatch: "\eb61";
$ti-icon-column-insert-left: "\ee44";
$ti-icon-column-insert-right: "\ee45";
$ti-icon-columns: "\eb83";
$ti-icon-comet: "\ec76";
$ti-icon-command: "\ea78";
$ti-icon-compass: "\ea79";
$ti-icon-confetti: "\ee46";
$ti-icon-container: "\ee47";
$ti-icon-contrast: "\ec4e";
$ti-icon-copy: "\ea7a";
$ti-icon-copyleft: "\ec3d";
$ti-icon-copyright: "\ea7b";
$ti-icon-corner-down-left: "\ea7c";
$ti-icon-corner-down-left-double: "\ee48";
$ti-icon-corner-down-right: "\ea7d";
$ti-icon-corner-down-right-double: "\ee49";
$ti-icon-corner-left-down: "\ea7e";
$ti-icon-corner-left-down-double: "\ee4a";
$ti-icon-corner-left-up: "\ea7f";
$ti-icon-corner-left-up-double: "\ee4b";
$ti-icon-corner-right-down: "\ea80";
$ti-icon-corner-right-down-double: "\ee4c";
$ti-icon-corner-right-up: "\ea81";
$ti-icon-corner-right-up-double: "\ee4d";
$ti-icon-corner-up-left: "\ea82";
$ti-icon-corner-up-left-double: "\ee4e";
$ti-icon-corner-up-right: "\ea83";
$ti-icon-corner-up-right-double: "\ee4f";
$ti-icon-credit-card: "\ea84";
$ti-icon-credit-card-off: "\ed11";
$ti-icon-crop: "\ea85";
$ti-icon-crosshair: "\ec3e";
$ti-icon-crown: "\ed12";
$ti-icon-crown-off: "\ee50";
$ti-icon-curly-loop: "\ecda";
$ti-icon-currency-bahraini: "\ee51";
$ti-icon-currency-bath: "\ee52";
$ti-icon-currency-bitcoin: "\ebab";
$ti-icon-currency-cent: "\ee53";
$ti-icon-currency-dinar: "\ee54";
$ti-icon-currency-dirham: "\ee55";
$ti-icon-currency-dollar: "\eb84";
$ti-icon-currency-dollar-australian: "\ee56";
$ti-icon-currency-dollar-canadian: "\ee57";
$ti-icon-currency-dollar-singapore: "\ee58";
$ti-icon-currency-ethereum: "\ee59";
$ti-icon-currency-euro: "\eb85";
$ti-icon-currency-forint: "\ee5a";
$ti-icon-currency-frank: "\ee5b";
$ti-icon-currency-krone-czech: "\ee5c";
$ti-icon-currency-krone-danish: "\ee5d";
$ti-icon-currency-krone-swedish: "\ee5e";
$ti-icon-currency-leu: "\ee5f";
$ti-icon-currency-lira: "\ee60";
$ti-icon-currency-litecoin: "\ee61";
$ti-icon-currency-naira: "\ee62";
$ti-icon-currency-pound: "\ebac";
$ti-icon-currency-real: "\ee63";
$ti-icon-currency-renminbi: "\ee64";
$ti-icon-currency-ripple: "\ee65";
$ti-icon-currency-riyal: "\ee66";
$ti-icon-currency-rubel: "\ee67";
$ti-icon-currency-rupee: "\ebad";
$ti-icon-currency-shekel: "\ee68";
$ti-icon-currency-taka: "\ee69";
$ti-icon-currency-tugrik: "\ee6a";
$ti-icon-currency-won: "\ee6b";
$ti-icon-currency-yen: "\ebae";
$ti-icon-currency-zloty: "\ee6c";
$ti-icon-current-location: "\ecef";
$ti-icon-cursor-text: "\ee6d";
$ti-icon-cut: "\ea86";
$ti-icon-dashboard: "\ea87";
$ti-icon-database: "\ea88";
$ti-icon-database-export: "\ee6e";
$ti-icon-database-import: "\ee6f";
$ti-icon-database-off: "\ee70";
$ti-icon-details: "\ee71";
$ti-icon-device-analytics: "\ee72";
$ti-icon-device-audio-tape: "\ee73";
$ti-icon-device-cctv: "\ee74";
$ti-icon-device-computer-camera: "\ee76";
$ti-icon-device-computer-camera-off: "\ee75";
$ti-icon-device-desktop: "\ea89";
$ti-icon-device-desktop-analytics: "\ee77";
$ti-icon-device-desktop-off: "\ee78";
$ti-icon-device-floppy: "\eb62";
$ti-icon-device-gamepad: "\eb63";
$ti-icon-device-laptop: "\eb64";
$ti-icon-device-mobile: "\ea8a";
$ti-icon-device-mobile-message: "\ee79";
$ti-icon-device-mobile-rotated: "\ecdb";
$ti-icon-device-mobile-vibration: "\eb86";
$ti-icon-device-speaker: "\ea8b";
$ti-icon-device-tablet: "\ea8c";
$ti-icon-device-tv: "\ea8d";
$ti-icon-device-watch: "\ebf9";
$ti-icon-devices: "\eb87";
$ti-icon-devices-2: "\ed29";
$ti-icon-devices-pc: "\ee7a";
$ti-icon-diamond: "\eb65";
$ti-icon-dice: "\eb66";
$ti-icon-dimensions: "\ee7b";
$ti-icon-direction: "\ebfb";
$ti-icon-direction-horizontal: "\ebfa";
$ti-icon-directions: "\ea8e";
$ti-icon-disabled: "\ea8f";
$ti-icon-disabled-2: "\ebaf";
$ti-icon-disc: "\ea90";
$ti-icon-discount: "\ebbd";
$ti-icon-discount-2: "\ee7c";
$ti-icon-divide: "\ed5c";
$ti-icon-dna: "\ee7d";
$ti-icon-dots: "\ea95";
$ti-icon-dots-circle-horizontal: "\ea91";
$ti-icon-dots-diagonal: "\ea93";
$ti-icon-dots-diagonal-2: "\ea92";
$ti-icon-dots-vertical: "\ea94";
$ti-icon-download: "\ea96";
$ti-icon-drag-drop: "\eb89";
$ti-icon-drag-drop-2: "\eb88";
$ti-icon-drone: "\ed79";
$ti-icon-drone-off: "\ee7e";
$ti-icon-droplet: "\ea97";
$ti-icon-droplet-filled: "\ee80";
$ti-icon-droplet-filled-2: "\ee7f";
$ti-icon-droplet-half: "\ee82";
$ti-icon-droplet-half-2: "\ee81";
$ti-icon-droplet-off: "\ee83";
$ti-icon-ear: "\ebce";
$ti-icon-ear-off: "\ee84";
$ti-icon-edit: "\ea98";
$ti-icon-edit-circle: "\ee85";
$ti-icon-egg: "\eb8a";
$ti-icon-emphasis: "\ebcf";
$ti-icon-equal: "\ee87";
$ti-icon-equal-not: "\ee86";
$ti-icon-eraser: "\eb8b";
$ti-icon-exchange: "\ebe7";
$ti-icon-exposure: "\eb8c";
$ti-icon-external-link: "\ea99";
$ti-icon-eye: "\ea9a";
$ti-icon-eye-check: "\ee88";
$ti-icon-eye-off: "\ecf0";
$ti-icon-eyeglass: "\ee8a";
$ti-icon-eyeglass-2: "\ee89";
$ti-icon-face-id: "\ea9b";
$ti-icon-fall: "\ecb9";
$ti-icon-feather: "\ee8b";
$ti-icon-file: "\eaa4";
$ti-icon-file-alert: "\ede6";
$ti-icon-file-analytics: "\ede7";
$ti-icon-file-certificate: "\ed4d";
$ti-icon-file-check: "\ea9c";
$ti-icon-file-code: "\ebd0";
$ti-icon-file-code-2: "\ede8";
$ti-icon-file-diff: "\ecf1";
$ti-icon-file-dislike: "\ed2a";
$ti-icon-file-download: "\ea9d";
$ti-icon-file-export: "\ede9";
$ti-icon-file-horizontal: "\ebb0";
$ti-icon-file-import: "\edea";
$ti-icon-file-info: "\edec";
$ti-icon-file-invoice: "\eb67";
$ti-icon-file-like: "\ed2b";
$ti-icon-file-minus: "\ea9e";
$ti-icon-file-music: "\ea9f";
$ti-icon-file-off: "\ecf2";
$ti-icon-file-phone: "\ecdc";
$ti-icon-file-plus: "\eaa0";
$ti-icon-file-report: "\eded";
$ti-icon-file-search: "\ed5d";
$ti-icon-file-shredder: "\eaa1";
$ti-icon-file-symlink: "\ed53";
$ti-icon-file-text: "\eaa2";
$ti-icon-file-upload: "\ec91";
$ti-icon-file-x: "\eaa3";
$ti-icon-file-zip: "\ed4e";
$ti-icon-files: "\edef";
$ti-icon-files-off: "\edee";
$ti-icon-filter: "\eaa5";
$ti-icon-filter-off: "\ed2c";
$ti-icon-fingerprint: "\ebd1";
$ti-icon-firetruck: "\ebe8";
$ti-icon-flag: "\eaa6";
$ti-icon-flag-2: "\ee8c";
$ti-icon-flag-3: "\ee8d";
$ti-icon-flame: "\ec2c";
$ti-icon-flare: "\ee8e";
$ti-icon-flask: "\ebd2";
$ti-icon-flip-horizontal: "\eaa7";
$ti-icon-flip-vertical: "\eaa8";
$ti-icon-float-center: "\ebb1";
$ti-icon-float-left: "\ebb2";
$ti-icon-float-none: "\ed13";
$ti-icon-float-right: "\ebb3";
$ti-icon-focus: "\eb8d";
$ti-icon-focus-2: "\ebd3";
$ti-icon-fold: "\ed56";
$ti-icon-fold-down: "\ed54";
$ti-icon-fold-up: "\ed55";
$ti-icon-folder: "\eaad";
$ti-icon-folder-minus: "\eaaa";
$ti-icon-folder-off: "\ed14";
$ti-icon-folder-plus: "\eaab";
$ti-icon-folder-x: "\eaac";
$ti-icon-folders: "\eaae";
$ti-icon-forbid: "\ebd5";
$ti-icon-forbid-2: "\ebd4";
$ti-icon-forklift: "\ebe9";
$ti-icon-forms: "\ee8f";
$ti-icon-frame: "\eaaf";
$ti-icon-friends: "\eab0";
$ti-icon-gas-station: "\ec7d";
$ti-icon-gauge: "\eab1";
$ti-icon-geometry: "\ee90";
$ti-icon-ghost: "\eb8e";
$ti-icon-gift: "\eb68";
$ti-icon-git-branch: "\eab2";
$ti-icon-git-commit: "\eab3";
$ti-icon-git-compare: "\eab4";
$ti-icon-git-fork: "\eb8f";
$ti-icon-git-merge: "\eab5";
$ti-icon-git-pull-request: "\eab6";
$ti-icon-glass: "\eab8";
$ti-icon-glass-full: "\eab7";
$ti-icon-glass-off: "\ee91";
$ti-icon-globe: "\eab9";
$ti-icon-golf: "\ed8c";
$ti-icon-gps: "\ed7a";
$ti-icon-grain: "\ee92";
$ti-icon-grid-dots: "\eaba";
$ti-icon-grip-horizontal: "\ec00";
$ti-icon-grip-vertical: "\ec01";
$ti-icon-growth: "\ee93";
$ti-icon-h-1: "\ec94";
$ti-icon-h-2: "\ec95";
$ti-icon-h-3: "\ec96";
$ti-icon-h-4: "\ec97";
$ti-icon-h-5: "\ec98";
$ti-icon-h-6: "\ec99";
$ti-icon-hand-finger: "\ee94";
$ti-icon-hand-little-finger: "\ee95";
$ti-icon-hand-middle-finger: "\ec2d";
$ti-icon-hand-off: "\ed15";
$ti-icon-hand-ring-finger: "\ee96";
$ti-icon-hand-rock: "\ee97";
$ti-icon-hand-stop: "\ec2e";
$ti-icon-hand-three-fingers: "\ee98";
$ti-icon-hand-two-fingers: "\ee99";
$ti-icon-hanger: "\ee9a";
$ti-icon-hash: "\eabc";
$ti-icon-heading: "\ee9b";
$ti-icon-headphones: "\eabd";
$ti-icon-headphones-off: "\ed1d";
$ti-icon-headset: "\eb90";
$ti-icon-heart: "\eabe";
$ti-icon-heart-broken: "\ecba";
$ti-icon-helicopter: "\ed8e";
$ti-icon-helicopter-landing: "\ed8d";
$ti-icon-help: "\eabf";
$ti-icon-hexagon: "\ec02";
$ti-icon-hexagon-off: "\ee9c";
$ti-icon-hierarchy: "\ee9e";
$ti-icon-hierarchy-2: "\ee9d";
$ti-icon-history: "\ebea";
$ti-icon-home: "\eac1";
$ti-icon-home-2: "\eac0";
$ti-icon-ice-cream: "\eac2";
$ti-icon-ice-cream-2: "\ee9f";
$ti-icon-id: "\eac3";
$ti-icon-inbox: "\eac4";
$ti-icon-indent-decrease: "\eb91";
$ti-icon-indent-increase: "\eb92";
$ti-icon-infinity: "\eb69";
$ti-icon-info-circle: "\eac5";
$ti-icon-info-square: "\eac6";
$ti-icon-italic: "\eb93";
$ti-icon-jump-rope: "\ed8f";
$ti-icon-karate: "\ed32";
$ti-icon-key: "\eac7";
$ti-icon-keyboard: "\ebd6";
$ti-icon-keyboard-hide: "\ec7e";
$ti-icon-keyboard-off: "\eea0";
$ti-icon-keyboard-show: "\ec7f";
$ti-icon-language: "\ebbe";
$ti-icon-layers-difference: "\eac8";
$ti-icon-layers-intersect: "\eac9";
$ti-icon-layers-linked: "\eea1";
$ti-icon-layers-subtract: "\eaca";
$ti-icon-layers-union: "\eacb";
$ti-icon-layout: "\eadb";
$ti-icon-layout-2: "\eacc";
$ti-icon-layout-align-bottom: "\eacd";
$ti-icon-layout-align-center: "\eace";
$ti-icon-layout-align-left: "\eacf";
$ti-icon-layout-align-middle: "\ead0";
$ti-icon-layout-align-right: "\ead1";
$ti-icon-layout-align-top: "\ead2";
$ti-icon-layout-bottombar: "\ead3";
$ti-icon-layout-cards: "\ec13";
$ti-icon-layout-columns: "\ead4";
$ti-icon-layout-distribute-horizontal: "\ead5";
$ti-icon-layout-distribute-vertical: "\ead6";
$ti-icon-layout-grid: "\edba";
$ti-icon-layout-grid-add: "\edb9";
$ti-icon-layout-kanban: "\ec3f";
$ti-icon-layout-list: "\ec14";
$ti-icon-layout-navbar: "\ead7";
$ti-icon-layout-rows: "\ead8";
$ti-icon-layout-sidebar: "\eada";
$ti-icon-layout-sidebar-right: "\ead9";
$ti-icon-leaf: "\ed4f";
$ti-icon-lego: "\eadc";
$ti-icon-letter-a: "\ec50";
$ti-icon-letter-b: "\ec51";
$ti-icon-letter-c: "\ec52";
$ti-icon-letter-case: "\eea5";
$ti-icon-letter-case-lower: "\eea2";
$ti-icon-letter-case-toggle: "\eea3";
$ti-icon-letter-case-upper: "\eea4";
$ti-icon-letter-d: "\ec53";
$ti-icon-letter-e: "\ec54";
$ti-icon-letter-f: "\ec55";
$ti-icon-letter-g: "\ec56";
$ti-icon-letter-h: "\ec57";
$ti-icon-letter-i: "\ec58";
$ti-icon-letter-j: "\ec59";
$ti-icon-letter-k: "\ec5a";
$ti-icon-letter-l: "\ec5b";
$ti-icon-letter-m: "\ec5c";
$ti-icon-letter-n: "\ec5d";
$ti-icon-letter-o: "\ec5e";
$ti-icon-letter-p: "\ec5f";
$ti-icon-letter-q: "\ec60";
$ti-icon-letter-r: "\ec61";
$ti-icon-letter-s: "\ec62";
$ti-icon-letter-spacing: "\eea6";
$ti-icon-letter-t: "\ec63";
$ti-icon-letter-u: "\ec64";
$ti-icon-letter-v: "\ec65";
$ti-icon-letter-w: "\ec66";
$ti-icon-letter-x: "\ec67";
$ti-icon-letter-y: "\ec68";
$ti-icon-letter-z: "\ec69";
$ti-icon-letters-case: "\ec6a";
$ti-icon-license: "\ebc0";
$ti-icon-lifebuoy: "\eadd";
$ti-icon-line: "\ec40";
$ti-icon-line-dashed: "\eea7";
$ti-icon-line-dotted: "\eea8";
$ti-icon-line-height: "\eb94";
$ti-icon-link: "\eade";
$ti-icon-list: "\eb6b";
$ti-icon-list-check: "\eb6a";
$ti-icon-list-search: "\eea9";
$ti-icon-live-photo: "\eadf";
$ti-icon-live-view: "\ec6b";
$ti-icon-loader: "\eca3";
$ti-icon-loader-quarter: "\eca2";
$ti-icon-location: "\eae0";
$ti-icon-lock: "\eae2";
$ti-icon-lock-access: "\eeaa";
$ti-icon-lock-off: "\ed1e";
$ti-icon-lock-open: "\eae1";
$ti-icon-login: "\eba7";
$ti-icon-logout: "\eba8";
$ti-icon-macro: "\eeab";
$ti-icon-magnet: "\eae3";
$ti-icon-mail: "\eae5";
$ti-icon-mail-forward: "\eeac";
$ti-icon-mail-opened: "\eae4";
$ti-icon-mailbox: "\eead";
$ti-icon-man: "\eae6";
$ti-icon-manual-gearbox: "\ed7b";
$ti-icon-map: "\eae9";
$ti-icon-map-2: "\eae7";
$ti-icon-map-pin: "\eae8";
$ti-icon-map-pin-off: "\ecf3";
$ti-icon-map-pins: "\ed5e";
$ti-icon-markdown: "\ec41";
$ti-icon-marquee: "\ec77";
$ti-icon-marquee-2: "\eeae";
$ti-icon-mars: "\ec80";
$ti-icon-mask: "\eeb0";
$ti-icon-mask-off: "\eeaf";
$ti-icon-massage: "\eeb1";
$ti-icon-math: "\ebeb";
$ti-icon-math-function: "\eeb2";
$ti-icon-math-symbols: "\eeb3";
$ti-icon-maximize: "\eaea";
$ti-icon-medal: "\ec78";
$ti-icon-medical-cross: "\ec2f";
$ti-icon-menu: "\eaeb";
$ti-icon-menu-2: "\ec42";
$ti-icon-message: "\eaef";
$ti-icon-message-2: "\eaec";
$ti-icon-message-circle: "\eaed";
$ti-icon-message-circle-2: "\ed3f";
$ti-icon-message-circle-off: "\ed40";
$ti-icon-message-dots: "\eaee";
$ti-icon-message-off: "\ed41";
$ti-icon-message-plus: "\ec9a";
$ti-icon-message-report: "\ec9b";
$ti-icon-messages: "\eb6c";
$ti-icon-messages-off: "\ed42";
$ti-icon-microphone: "\eaf0";
$ti-icon-microphone-off: "\ed16";
$ti-icon-minimize: "\eaf1";
$ti-icon-minus: "\eaf2";
$ti-icon-minus-vertical: "\eeb4";
$ti-icon-mist: "\ec30";
$ti-icon-mood-boy: "\ed2d";
$ti-icon-mood-confuzed: "\eaf3";
$ti-icon-mood-crazy-happy: "\ed90";
$ti-icon-mood-cry: "\ecbb";
$ti-icon-mood-empty: "\eeb5";
$ti-icon-mood-happy: "\eaf4";
$ti-icon-mood-kid: "\ec03";
$ti-icon-mood-neutral: "\eaf5";
$ti-icon-mood-sad: "\eaf6";
$ti-icon-mood-smile: "\eaf7";
$ti-icon-mood-suprised: "\ec04";
$ti-icon-mood-tongue: "\eb95";
$ti-icon-moon: "\eaf8";
$ti-icon-moon-2: "\ece6";
$ti-icon-moon-stars: "\ece7";
$ti-icon-moped: "\ecbc";
$ti-icon-motorbike: "\eeb6";
$ti-icon-mouse: "\eaf9";
$ti-icon-movie: "\eafa";
$ti-icon-mug: "\eafb";
$ti-icon-music: "\eafc";
$ti-icon-new-section: "\ebc1";
$ti-icon-news: "\eafd";
$ti-icon-nfc: "\eeb7";
$ti-icon-note: "\eb6d";
$ti-icon-notebook: "\eb96";
$ti-icon-notes: "\eb6e";
$ti-icon-notification: "\eafe";
$ti-icon-number-0: "\edf0";
$ti-icon-number-1: "\edf1";
$ti-icon-number-2: "\edf2";
$ti-icon-number-3: "\edf3";
$ti-icon-number-4: "\edf4";
$ti-icon-number-5: "\edf5";
$ti-icon-number-6: "\edf6";
$ti-icon-number-7: "\edf7";
$ti-icon-number-8: "\edf8";
$ti-icon-number-9: "\edf9";
$ti-icon-octagon: "\ecbd";
$ti-icon-octagon-off: "\eeb8";
$ti-icon-old: "\eeb9";
$ti-icon-olympics: "\eeba";
$ti-icon-omega: "\eb97";
$ti-icon-outlet: "\ebd7";
$ti-icon-overline: "\eebb";
$ti-icon-package: "\eaff";
$ti-icon-pacman: "\eebc";
$ti-icon-page-break: "\ec81";
$ti-icon-paint: "\eb00";
$ti-icon-palette: "\eb01";
$ti-icon-panorama-horizontal: "\ed33";
$ti-icon-panorama-vertical: "\ed34";
$ti-icon-paperclip: "\eb02";
$ti-icon-parachute: "\ed7c";
$ti-icon-parentheses: "\ebd8";
$ti-icon-parking: "\eb03";
$ti-icon-peace: "\ecbe";
$ti-icon-pencil: "\eb04";
$ti-icon-pennant: "\ed7d";
$ti-icon-percentage: "\ecf4";
$ti-icon-perspective: "\eebd";
$ti-icon-phone: "\eb09";
$ti-icon-phone-call: "\eb05";
$ti-icon-phone-calling: "\ec43";
$ti-icon-phone-check: "\ec05";
$ti-icon-phone-incoming: "\eb06";
$ti-icon-phone-off: "\ecf5";
$ti-icon-phone-outgoing: "\eb07";
$ti-icon-phone-pause: "\eb08";
$ti-icon-phone-plus: "\ec06";
$ti-icon-phone-x: "\ec07";
$ti-icon-photo: "\eb0a";
$ti-icon-photo-off: "\ecf6";
$ti-icon-physotherapist: "\eebe";
$ti-icon-picture-in-picture: "\ed35";
$ti-icon-picture-in-picture-off: "\ed43";
$ti-icon-picture-in-picture-on: "\ed44";
$ti-icon-pill: "\ec44";
$ti-icon-pin: "\ec9c";
$ti-icon-pinned: "\ed60";
$ti-icon-pinned-off: "\ed5f";
$ti-icon-pizza: "\edbb";
$ti-icon-plane: "\eb6f";
$ti-icon-plane-arrival: "\eb99";
$ti-icon-plane-departure: "\eb9a";
$ti-icon-planet: "\ec08";
$ti-icon-plant: "\ed50";
$ti-icon-plant-2: "\ed7e";
$ti-icon-play-card: "\eebf";
$ti-icon-player-pause: "\ed45";
$ti-icon-player-play: "\ed46";
$ti-icon-player-record: "\ed47";
$ti-icon-player-skip-back: "\ed48";
$ti-icon-player-skip-forward: "\ed49";
$ti-icon-player-stop: "\ed4a";
$ti-icon-player-track-next: "\ed4b";
$ti-icon-player-track-prev: "\ed4c";
$ti-icon-playlist: "\eec0";
$ti-icon-plug: "\ebd9";
$ti-icon-plus: "\eb0b";
$ti-icon-point: "\eb0c";
$ti-icon-pokeball: "\eec1";
$ti-icon-polaroid: "\eec2";
$ti-icon-pool: "\ed91";
$ti-icon-power: "\eb0d";
$ti-icon-pray: "\ecbf";
$ti-icon-presentation: "\eb70";
$ti-icon-presentation-analytics: "\eec3";
$ti-icon-printer: "\eb0e";
$ti-icon-prompt: "\eb0f";
$ti-icon-propeller: "\eec4";
$ti-icon-puzzle: "\eb10";
$ti-icon-pyramid: "\eec5";
$ti-icon-qrcode: "\eb11";
$ti-icon-question-mark: "\ec9d";
$ti-icon-radioactive: "\ecc0";
$ti-icon-radius-bottom-left: "\eec6";
$ti-icon-radius-bottom-right: "\eec7";
$ti-icon-radius-top-left: "\eec8";
$ti-icon-radius-top-right: "\eec9";
$ti-icon-rainbow: "\edbc";
$ti-icon-receipt: "\edfd";
$ti-icon-receipt-2: "\edfa";
$ti-icon-receipt-off: "\edfb";
$ti-icon-receipt-refund: "\edfc";
$ti-icon-receipt-tax: "\edbd";
$ti-icon-recharging: "\eeca";
$ti-icon-record-mail: "\eb12";
$ti-icon-rectangle: "\ed37";
$ti-icon-rectangle-vertical: "\ed36";
$ti-icon-recycle: "\eb9b";
$ti-icon-refresh: "\eb13";
$ti-icon-refresh-alert: "\ed57";
$ti-icon-registered: "\eb14";
$ti-icon-relation-many-to-many: "\ed7f";
$ti-icon-relation-one-to-many: "\ed80";
$ti-icon-relation-one-to-one: "\ed81";
$ti-icon-repeat: "\eb72";
$ti-icon-repeat-once: "\eb71";
$ti-icon-replace: "\ebc7";
$ti-icon-report: "\eece";
$ti-icon-report-analytics: "\eecb";
$ti-icon-report-medical: "\eecc";
$ti-icon-report-money: "\eecd";
$ti-icon-resize: "\eecf";
$ti-icon-ripple: "\ed82";
$ti-icon-road-sign: "\ecdd";
$ti-icon-rocket: "\ec45";
$ti-icon-rotate: "\eb16";
$ti-icon-rotate-2: "\ebb4";
$ti-icon-rotate-clockwise: "\eb15";
$ti-icon-rotate-clockwise-2: "\ebb5";
$ti-icon-rotate-rectangle: "\ec15";
$ti-icon-route: "\eb17";
$ti-icon-router: "\eb18";
$ti-icon-row-insert-bottom: "\eed0";
$ti-icon-row-insert-top: "\eed1";
$ti-icon-rss: "\eb19";
$ti-icon-ruler: "\eb1a";
$ti-icon-ruler-2: "\eed2";
$ti-icon-run: "\ec82";
$ti-icon-sailboat: "\ec83";
$ti-icon-satellite: "\eed3";
$ti-icon-scale: "\ebc2";
$ti-icon-scan: "\ebc8";
$ti-icon-school: "\ecf7";
$ti-icon-scissors: "\eb1b";
$ti-icon-scooter: "\ec6c";
$ti-icon-scooter-electric: "\ecc1";
$ti-icon-screen-share: "\ed18";
$ti-icon-screen-share-off: "\ed17";
$ti-icon-scuba-mask: "\eed4";
$ti-icon-search: "\eb1c";
$ti-icon-section: "\eed5";
$ti-icon-seeding: "\ed51";
$ti-icon-select: "\ec9e";
$ti-icon-selector: "\eb1d";
$ti-icon-send: "\eb1e";
$ti-icon-separator: "\ebda";
$ti-icon-separator-horizontal: "\ec79";
$ti-icon-separator-vertical: "\ec7a";
$ti-icon-server: "\eb1f";
$ti-icon-servicemark: "\ec09";
$ti-icon-settings: "\eb20";
$ti-icon-settings-automation: "\eed6";
$ti-icon-shadow: "\eed8";
$ti-icon-shadow-off: "\eed7";
$ti-icon-shape: "\eb9c";
$ti-icon-shape-2: "\eed9";
$ti-icon-shape-3: "\eeda";
$ti-icon-share: "\eb21";
$ti-icon-shield: "\eb24";
$ti-icon-shield-check: "\eb22";
$ti-icon-shield-lock: "\ed58";
$ti-icon-shield-off: "\ecf8";
$ti-icon-shield-x: "\eb23";
$ti-icon-ship: "\ec84";
$ti-icon-shirt: "\ec0a";
$ti-icon-shopping-cart: "\eb25";
$ti-icon-shopping-cart-discount: "\eedb";
$ti-icon-shopping-cart-off: "\eedc";
$ti-icon-shopping-cart-plus: "\eedd";
$ti-icon-shopping-cart-x: "\eede";
$ti-icon-shredder: "\eedf";
$ti-icon-signature: "\eee0";
$ti-icon-sitemap: "\eb9d";
$ti-icon-skateboard: "\ecc2";
$ti-icon-slice: "\ebdb";
$ti-icon-slideshow: "\ebc9";
$ti-icon-smart-home: "\ecde";
$ti-icon-smoking: "\ecc4";
$ti-icon-smoking-no: "\ecc3";
$ti-icon-snowflake: "\ec0b";
$ti-icon-soccer-field: "\ed92";
$ti-icon-social: "\ebec";
$ti-icon-sock: "\eee1";
$ti-icon-sort-ascending: "\eb26";
$ti-icon-sort-ascending-2: "\eee2";
$ti-icon-sort-descending: "\eb27";
$ti-icon-sort-descending-2: "\eee3";
$ti-icon-space: "\ec0c";
$ti-icon-speakerphone: "\ed61";
$ti-icon-speedboat: "\ed93";
$ti-icon-sport-billard: "\eee4";
$ti-icon-square: "\eb2c";
$ti-icon-square-0: "\eee5";
$ti-icon-square-1: "\eee6";
$ti-icon-square-2: "\eee7";
$ti-icon-square-3: "\eee8";
$ti-icon-square-4: "\eee9";
$ti-icon-square-5: "\eeea";
$ti-icon-square-6: "\eeeb";
$ti-icon-square-7: "\eeec";
$ti-icon-square-8: "\eeed";
$ti-icon-square-9: "\eeee";
$ti-icon-square-check: "\eb28";
$ti-icon-square-dot: "\ed59";
$ti-icon-square-forbid: "\ed5b";
$ti-icon-square-forbid-2: "\ed5a";
$ti-icon-square-minus: "\eb29";
$ti-icon-square-off: "\eeef";
$ti-icon-square-plus: "\eb2a";
$ti-icon-square-root: "\eef1";
$ti-icon-square-root-2: "\eef0";
$ti-icon-square-rotated: "\ecdf";
$ti-icon-square-rotated-off: "\eef2";
$ti-icon-square-toggle: "\eef4";
$ti-icon-square-toggle-horizontal: "\eef3";
$ti-icon-square-x: "\eb2b";
$ti-icon-squares-diagonal: "\eef5";
$ti-icon-squares-filled: "\eef6";
$ti-icon-stack: "\eb2d";
$ti-icon-stack-2: "\eef7";
$ti-icon-stairs: "\eca6";
$ti-icon-stairs-down: "\eca4";
$ti-icon-stairs-up: "\eca5";
$ti-icon-star: "\eb2e";
$ti-icon-star-half: "\ed19";
$ti-icon-star-off: "\ed62";
$ti-icon-stars: "\ed38";
$ti-icon-steering-wheel: "\ec7b";
$ti-icon-step-into: "\ece0";
$ti-icon-step-out: "\ece1";
$ti-icon-stethoscope: "\edbe";
$ti-icon-sticker: "\eb2f";
$ti-icon-strikethrough: "\eb9e";
$ti-icon-submarine: "\ed94";
$ti-icon-subscript: "\eb9f";
$ti-icon-subtask: "\ec9f";
$ti-icon-sum: "\eb73";
$ti-icon-sun: "\eb30";
$ti-icon-sun-off: "\ed63";
$ti-icon-sunset: "\ec31";
$ti-icon-sunshine: "\ec32";
$ti-icon-superscript: "\eba0";
$ti-icon-swimming: "\ec92";
$ti-icon-switch: "\eb33";
$ti-icon-switch-2: "\edbf";
$ti-icon-switch-3: "\edc0";
$ti-icon-switch-horizontal: "\eb31";
$ti-icon-switch-vertical: "\eb32";
$ti-icon-table: "\eba1";
$ti-icon-table-export: "\eef8";
$ti-icon-table-import: "\eef9";
$ti-icon-table-off: "\eefa";
$ti-icon-tag: "\eb34";
$ti-icon-tallymark-1: "\ec46";
$ti-icon-tallymark-2: "\ec47";
$ti-icon-tallymark-3: "\ec48";
$ti-icon-tallymark-4: "\ec49";
$ti-icon-tallymarks: "\ec4a";
$ti-icon-tank: "\ed95";
$ti-icon-target: "\eb35";
$ti-icon-temperature: "\eb38";
$ti-icon-temperature-celsius: "\eb36";
$ti-icon-temperature-fahrenheit: "\eb37";
$ti-icon-temperature-minus: "\ebed";
$ti-icon-temperature-plus: "\ebee";
$ti-icon-template: "\eb39";
$ti-icon-tent: "\eefb";
$ti-icon-terminal: "\ebdc";
$ti-icon-terminal-2: "\ebef";
$ti-icon-test-pipe: "\eb3a";
$ti-icon-text-direction-ltr: "\eefc";
$ti-icon-text-direction-rtl: "\eefd";
$ti-icon-text-wrap: "\ebdd";
$ti-icon-text-wrap-disabled: "\eca7";
$ti-icon-thumb-down: "\eb3b";
$ti-icon-thumb-up: "\eb3c";
$ti-icon-ticket: "\eb3d";
$ti-icon-tilt-shift: "\eefe";
$ti-icon-tir: "\ebf0";
$ti-icon-toggle-left: "\eb3e";
$ti-icon-toggle-right: "\eb3f";
$ti-icon-tool: "\eb40";
$ti-icon-tools: "\ebca";
$ti-icon-tools-kitchen: "\ed64";
$ti-icon-tools-kitchen-2: "\eeff";
$ti-icon-tornado: "\ece2";
$ti-icon-tournament: "\ecd0";
$ti-icon-track: "\ef00";
$ti-icon-tractor: "\ec0d";
$ti-icon-trademark: "\ec0e";
$ti-icon-traffic-cone: "\ec0f";
$ti-icon-traffic-lights: "\ed39";
$ti-icon-train: "\ed96";
$ti-icon-trash: "\eb41";
$ti-icon-trash-off: "\ed65";
$ti-icon-tree: "\ef01";
$ti-icon-trees: "\ec10";
$ti-icon-trending-down: "\eb42";
$ti-icon-trending-down-2: "\edc1";
$ti-icon-trending-down-3: "\edc2";
$ti-icon-trending-up: "\eb43";
$ti-icon-trending-up-2: "\edc3";
$ti-icon-trending-up-3: "\edc4";
$ti-icon-triangle: "\eb44";
$ti-icon-triangle-off: "\ef02";
$ti-icon-triangle-square-circle: "\ece8";
$ti-icon-trident: "\ecc5";
$ti-icon-trophy: "\eb45";
$ti-icon-truck: "\ebc4";
$ti-icon-truck-delivery: "\ec4b";
$ti-icon-truck-off: "\ef03";
$ti-icon-truck-return: "\ec4c";
$ti-icon-typography: "\ebc5";
$ti-icon-umbrella: "\ebf1";
$ti-icon-underline: "\eba2";
$ti-icon-unlink: "\eb46";
$ti-icon-upload: "\eb47";
$ti-icon-urgent: "\eb48";
$ti-icon-user: "\eb4d";
$ti-icon-user-check: "\eb49";
$ti-icon-user-exclamation: "\ec12";
$ti-icon-user-minus: "\eb4a";
$ti-icon-user-off: "\ecf9";
$ti-icon-user-plus: "\eb4b";
$ti-icon-user-x: "\eb4c";
$ti-icon-users: "\ebf2";
$ti-icon-vaccine: "\ef04";
$ti-icon-variable: "\ef05";
$ti-icon-vector: "\eca9";
$ti-icon-vector-beizer: "\ed3b";
$ti-icon-vector-beizer-2: "\ed3a";
$ti-icon-vector-triangle: "\eca8";
$ti-icon-venus: "\ec86";
$ti-icon-versions: "\ed52";
$ti-icon-video: "\ed22";
$ti-icon-video-minus: "\ed1f";
$ti-icon-video-off: "\ed20";
$ti-icon-video-plus: "\ed21";
$ti-icon-view-360: "\ed84";
$ti-icon-viewfinder: "\eb4e";
$ti-icon-viewport-narrow: "\ebf3";
$ti-icon-viewport-wide: "\ebf4";
$ti-icon-virus: "\eb74";
$ti-icon-virus-off: "\ed66";
$ti-icon-virus-search: "\ed67";
$ti-icon-volume: "\eb51";
$ti-icon-volume-2: "\eb4f";
$ti-icon-volume-3: "\eb50";
$ti-icon-walk: "\ec87";
$ti-icon-wallet: "\eb75";
$ti-icon-wand: "\ebcb";
$ti-icon-wave-saw-tool: "\ecd3";
$ti-icon-wave-sine: "\ecd4";
$ti-icon-wave-square: "\ecd5";
$ti-icon-wifi: "\eb52";
$ti-icon-wifi-0: "\eba3";
$ti-icon-wifi-1: "\eba4";
$ti-icon-wifi-2: "\eba5";
$ti-icon-wifi-off: "\ecfa";
$ti-icon-wind: "\ec34";
$ti-icon-windmill: "\ed85";
$ti-icon-window: "\ef06";
$ti-icon-wiper: "\ecab";
$ti-icon-wiper-wash: "\ecaa";
$ti-icon-woman: "\eb53";
$ti-icon-world: "\eb54";
$ti-icon-world-latitude: "\ed2e";
$ti-icon-world-longitude: "\ed2f";
$ti-icon-wrecking-ball: "\ed97";
$ti-icon-writing: "\ef08";
$ti-icon-writing-sign: "\ef07";
$ti-icon-x: "\eb55";
$ti-icon-yin-yang: "\ec35";
$ti-icon-zodiac-aquarius: "\ecac";
$ti-icon-zodiac-aries: "\ecad";
$ti-icon-zodiac-cancer: "\ecae";
$ti-icon-zodiac-capricorn: "\ecaf";
$ti-icon-zodiac-gemini: "\ecb0";
$ti-icon-zodiac-leo: "\ecb1";
$ti-icon-zodiac-libra: "\ecb2";
$ti-icon-zodiac-pisces: "\ecb3";
$ti-icon-zodiac-sagittarius: "\ecb4";
$ti-icon-zodiac-scorpio: "\ecb5";
$ti-icon-zodiac-taurus: "\ecb6";
$ti-icon-zodiac-virgo: "\ecb7";
$ti-icon-zoom-cancel: "\ec4d";
$ti-icon-zoom-check: "\ef09";
$ti-icon-zoom-in: "\eb56";
$ti-icon-zoom-money: "\ef0a";
$ti-icon-zoom-out: "\eb57";
$ti-icon-zoom-question: "\edeb";

.#{$ti-icon-prefix}-2fa:before {
    content: $ti-icon-2fa;
}
.#{$ti-icon-prefix}-3d-cube-sphere:before {
    content: $ti-icon-3d-cube-sphere;
}
.#{$ti-icon-prefix}-a-b:before {
    content: $ti-icon-a-b;
}
.#{$ti-icon-prefix}-access-point:before {
    content: $ti-icon-access-point;
}
.#{$ti-icon-prefix}-access-point-off:before {
    content: $ti-icon-access-point-off;
}
.#{$ti-icon-prefix}-accessible:before {
    content: $ti-icon-accessible;
}
.#{$ti-icon-prefix}-activity:before {
    content: $ti-icon-activity;
}
.#{$ti-icon-prefix}-ad:before {
    content: $ti-icon-ad;
}
.#{$ti-icon-prefix}-adjustments:before {
    content: $ti-icon-adjustments;
}
.#{$ti-icon-prefix}-adjustments-alt:before {
    content: $ti-icon-adjustments-alt;
}
.#{$ti-icon-prefix}-adjustments-horizontal:before {
    content: $ti-icon-adjustments-horizontal;
}
.#{$ti-icon-prefix}-aerial-lift:before {
    content: $ti-icon-aerial-lift;
}
.#{$ti-icon-prefix}-affiliate:before {
    content: $ti-icon-affiliate;
}
.#{$ti-icon-prefix}-alarm:before {
    content: $ti-icon-alarm;
}
.#{$ti-icon-prefix}-alert-circle:before {
    content: $ti-icon-alert-circle;
}
.#{$ti-icon-prefix}-alert-octagon:before {
    content: $ti-icon-alert-octagon;
}
.#{$ti-icon-prefix}-alert-triangle:before {
    content: $ti-icon-alert-triangle;
}
.#{$ti-icon-prefix}-alien:before {
    content: $ti-icon-alien;
}
.#{$ti-icon-prefix}-align-center:before {
    content: $ti-icon-align-center;
}
.#{$ti-icon-prefix}-align-justified:before {
    content: $ti-icon-align-justified;
}
.#{$ti-icon-prefix}-align-left:before {
    content: $ti-icon-align-left;
}
.#{$ti-icon-prefix}-align-right:before {
    content: $ti-icon-align-right;
}
.#{$ti-icon-prefix}-ambulance:before {
    content: $ti-icon-ambulance;
}
.#{$ti-icon-prefix}-anchor:before {
    content: $ti-icon-anchor;
}
.#{$ti-icon-prefix}-antenna-bars-1:before {
    content: $ti-icon-antenna-bars-1;
}
.#{$ti-icon-prefix}-antenna-bars-2:before {
    content: $ti-icon-antenna-bars-2;
}
.#{$ti-icon-prefix}-antenna-bars-3:before {
    content: $ti-icon-antenna-bars-3;
}
.#{$ti-icon-prefix}-antenna-bars-4:before {
    content: $ti-icon-antenna-bars-4;
}
.#{$ti-icon-prefix}-antenna-bars-5:before {
    content: $ti-icon-antenna-bars-5;
}
.#{$ti-icon-prefix}-aperture:before {
    content: $ti-icon-aperture;
}
.#{$ti-icon-prefix}-apps:before {
    content: $ti-icon-apps;
}
.#{$ti-icon-prefix}-archive:before {
    content: $ti-icon-archive;
}
.#{$ti-icon-prefix}-arrow-back:before {
    content: $ti-icon-arrow-back;
}
.#{$ti-icon-prefix}-arrow-back-up:before {
    content: $ti-icon-arrow-back-up;
}
.#{$ti-icon-prefix}-arrow-bar-down:before {
    content: $ti-icon-arrow-bar-down;
}
.#{$ti-icon-prefix}-arrow-bar-left:before {
    content: $ti-icon-arrow-bar-left;
}
.#{$ti-icon-prefix}-arrow-bar-right:before {
    content: $ti-icon-arrow-bar-right;
}
.#{$ti-icon-prefix}-arrow-bar-to-down:before {
    content: $ti-icon-arrow-bar-to-down;
}
.#{$ti-icon-prefix}-arrow-bar-to-left:before {
    content: $ti-icon-arrow-bar-to-left;
}
.#{$ti-icon-prefix}-arrow-bar-to-right:before {
    content: $ti-icon-arrow-bar-to-right;
}
.#{$ti-icon-prefix}-arrow-bar-to-up:before {
    content: $ti-icon-arrow-bar-to-up;
}
.#{$ti-icon-prefix}-arrow-bar-up:before {
    content: $ti-icon-arrow-bar-up;
}
.#{$ti-icon-prefix}-arrow-big-down:before {
    content: $ti-icon-arrow-big-down;
}
.#{$ti-icon-prefix}-arrow-big-left:before {
    content: $ti-icon-arrow-big-left;
}
.#{$ti-icon-prefix}-arrow-big-right:before {
    content: $ti-icon-arrow-big-right;
}
.#{$ti-icon-prefix}-arrow-big-top:before {
    content: $ti-icon-arrow-big-top;
}
.#{$ti-icon-prefix}-arrow-bottom-bar:before {
    content: $ti-icon-arrow-bottom-bar;
}
.#{$ti-icon-prefix}-arrow-bottom-circle:before {
    content: $ti-icon-arrow-bottom-circle;
}
.#{$ti-icon-prefix}-arrow-bottom-square:before {
    content: $ti-icon-arrow-bottom-square;
}
.#{$ti-icon-prefix}-arrow-bottom-tail:before {
    content: $ti-icon-arrow-bottom-tail;
}
.#{$ti-icon-prefix}-arrow-down:before {
    content: $ti-icon-arrow-down;
}
.#{$ti-icon-prefix}-arrow-down-circle:before {
    content: $ti-icon-arrow-down-circle;
}
.#{$ti-icon-prefix}-arrow-down-left:before {
    content: $ti-icon-arrow-down-left;
}
.#{$ti-icon-prefix}-arrow-down-left-circle:before {
    content: $ti-icon-arrow-down-left-circle;
}
.#{$ti-icon-prefix}-arrow-down-right:before {
    content: $ti-icon-arrow-down-right;
}
.#{$ti-icon-prefix}-arrow-down-right-circle:before {
    content: $ti-icon-arrow-down-right-circle;
}
.#{$ti-icon-prefix}-arrow-forward:before {
    content: $ti-icon-arrow-forward;
}
.#{$ti-icon-prefix}-arrow-forward-up:before {
    content: $ti-icon-arrow-forward-up;
}
.#{$ti-icon-prefix}-arrow-left:before {
    content: $ti-icon-arrow-left;
}
.#{$ti-icon-prefix}-arrow-left-bar:before {
    content: $ti-icon-arrow-left-bar;
}
.#{$ti-icon-prefix}-arrow-left-circle:before {
    content: $ti-icon-arrow-left-circle;
}
.#{$ti-icon-prefix}-arrow-left-square:before {
    content: $ti-icon-arrow-left-square;
}
.#{$ti-icon-prefix}-arrow-left-tail:before {
    content: $ti-icon-arrow-left-tail;
}
.#{$ti-icon-prefix}-arrow-loop-left:before {
    content: $ti-icon-arrow-loop-left;
}
.#{$ti-icon-prefix}-arrow-loop-right:before {
    content: $ti-icon-arrow-loop-right;
}
.#{$ti-icon-prefix}-arrow-narrow-down:before {
    content: $ti-icon-arrow-narrow-down;
}
.#{$ti-icon-prefix}-arrow-narrow-left:before {
    content: $ti-icon-arrow-narrow-left;
}
.#{$ti-icon-prefix}-arrow-narrow-right:before {
    content: $ti-icon-arrow-narrow-right;
}
.#{$ti-icon-prefix}-arrow-narrow-up:before {
    content: $ti-icon-arrow-narrow-up;
}
.#{$ti-icon-prefix}-arrow-ramp-left:before {
    content: $ti-icon-arrow-ramp-left;
}
.#{$ti-icon-prefix}-arrow-ramp-right:before {
    content: $ti-icon-arrow-ramp-right;
}
.#{$ti-icon-prefix}-arrow-right:before {
    content: $ti-icon-arrow-right;
}
.#{$ti-icon-prefix}-arrow-right-bar:before {
    content: $ti-icon-arrow-right-bar;
}
.#{$ti-icon-prefix}-arrow-right-circle:before {
    content: $ti-icon-arrow-right-circle;
}
.#{$ti-icon-prefix}-arrow-right-square:before {
    content: $ti-icon-arrow-right-square;
}
.#{$ti-icon-prefix}-arrow-right-tail:before {
    content: $ti-icon-arrow-right-tail;
}
.#{$ti-icon-prefix}-arrow-top-bar:before {
    content: $ti-icon-arrow-top-bar;
}
.#{$ti-icon-prefix}-arrow-top-circle:before {
    content: $ti-icon-arrow-top-circle;
}
.#{$ti-icon-prefix}-arrow-top-square:before {
    content: $ti-icon-arrow-top-square;
}
.#{$ti-icon-prefix}-arrow-top-tail:before {
    content: $ti-icon-arrow-top-tail;
}
.#{$ti-icon-prefix}-arrow-up:before {
    content: $ti-icon-arrow-up;
}
.#{$ti-icon-prefix}-arrow-up-circle:before {
    content: $ti-icon-arrow-up-circle;
}
.#{$ti-icon-prefix}-arrow-up-left:before {
    content: $ti-icon-arrow-up-left;
}
.#{$ti-icon-prefix}-arrow-up-left-circle:before {
    content: $ti-icon-arrow-up-left-circle;
}
.#{$ti-icon-prefix}-arrow-up-right:before {
    content: $ti-icon-arrow-up-right;
}
.#{$ti-icon-prefix}-arrow-up-right-circle:before {
    content: $ti-icon-arrow-up-right-circle;
}
.#{$ti-icon-prefix}-arrow-wave-left-down:before {
    content: $ti-icon-arrow-wave-left-down;
}
.#{$ti-icon-prefix}-arrow-wave-left-up:before {
    content: $ti-icon-arrow-wave-left-up;
}
.#{$ti-icon-prefix}-arrow-wave-right-down:before {
    content: $ti-icon-arrow-wave-right-down;
}
.#{$ti-icon-prefix}-arrow-wave-right-up:before {
    content: $ti-icon-arrow-wave-right-up;
}
.#{$ti-icon-prefix}-arrows-diagonal:before {
    content: $ti-icon-arrows-diagonal;
}
.#{$ti-icon-prefix}-arrows-diagonal-2:before {
    content: $ti-icon-arrows-diagonal-2;
}
.#{$ti-icon-prefix}-arrows-double-ne-sw:before {
    content: $ti-icon-arrows-double-ne-sw;
}
.#{$ti-icon-prefix}-arrows-double-nw-se:before {
    content: $ti-icon-arrows-double-nw-se;
}
.#{$ti-icon-prefix}-arrows-double-se-nw:before {
    content: $ti-icon-arrows-double-se-nw;
}
.#{$ti-icon-prefix}-arrows-double-sw-ne:before {
    content: $ti-icon-arrows-double-sw-ne;
}
.#{$ti-icon-prefix}-arrows-down:before {
    content: $ti-icon-arrows-down;
}
.#{$ti-icon-prefix}-arrows-down-up:before {
    content: $ti-icon-arrows-down-up;
}
.#{$ti-icon-prefix}-arrows-horizontal:before {
    content: $ti-icon-arrows-horizontal;
}
.#{$ti-icon-prefix}-arrows-join:before {
    content: $ti-icon-arrows-join;
}
.#{$ti-icon-prefix}-arrows-join-2:before {
    content: $ti-icon-arrows-join-2;
}
.#{$ti-icon-prefix}-arrows-left:before {
    content: $ti-icon-arrows-left;
}
.#{$ti-icon-prefix}-arrows-left-down:before {
    content: $ti-icon-arrows-left-down;
}
.#{$ti-icon-prefix}-arrows-left-right:before {
    content: $ti-icon-arrows-left-right;
}
.#{$ti-icon-prefix}-arrows-maximize:before {
    content: $ti-icon-arrows-maximize;
}
.#{$ti-icon-prefix}-arrows-minimize:before {
    content: $ti-icon-arrows-minimize;
}
.#{$ti-icon-prefix}-arrows-right:before {
    content: $ti-icon-arrows-right;
}
.#{$ti-icon-prefix}-arrows-right-down:before {
    content: $ti-icon-arrows-right-down;
}
.#{$ti-icon-prefix}-arrows-right-left:before {
    content: $ti-icon-arrows-right-left;
}
.#{$ti-icon-prefix}-arrows-sort:before {
    content: $ti-icon-arrows-sort;
}
.#{$ti-icon-prefix}-arrows-split:before {
    content: $ti-icon-arrows-split;
}
.#{$ti-icon-prefix}-arrows-split-2:before {
    content: $ti-icon-arrows-split-2;
}
.#{$ti-icon-prefix}-arrows-up:before {
    content: $ti-icon-arrows-up;
}
.#{$ti-icon-prefix}-arrows-up-down:before {
    content: $ti-icon-arrows-up-down;
}
.#{$ti-icon-prefix}-arrows-up-left:before {
    content: $ti-icon-arrows-up-left;
}
.#{$ti-icon-prefix}-arrows-up-right:before {
    content: $ti-icon-arrows-up-right;
}
.#{$ti-icon-prefix}-arrows-vertical:before {
    content: $ti-icon-arrows-vertical;
}
.#{$ti-icon-prefix}-artboard:before {
    content: $ti-icon-artboard;
}
.#{$ti-icon-prefix}-aspect-ratio:before {
    content: $ti-icon-aspect-ratio;
}
.#{$ti-icon-prefix}-at:before {
    content: $ti-icon-at;
}
.#{$ti-icon-prefix}-atom:before {
    content: $ti-icon-atom;
}
.#{$ti-icon-prefix}-atom-2:before {
    content: $ti-icon-atom-2;
}
.#{$ti-icon-prefix}-award:before {
    content: $ti-icon-award;
}
.#{$ti-icon-prefix}-backhoe:before {
    content: $ti-icon-backhoe;
}
.#{$ti-icon-prefix}-backspace:before {
    content: $ti-icon-backspace;
}
.#{$ti-icon-prefix}-ball-american-football:before {
    content: $ti-icon-ball-american-football;
}
.#{$ti-icon-prefix}-ball-basketball:before {
    content: $ti-icon-ball-basketball;
}
.#{$ti-icon-prefix}-ball-bowling:before {
    content: $ti-icon-ball-bowling;
}
.#{$ti-icon-prefix}-ball-football:before {
    content: $ti-icon-ball-football;
}
.#{$ti-icon-prefix}-ball-football-off:before {
    content: $ti-icon-ball-football-off;
}
.#{$ti-icon-prefix}-ball-tennis:before {
    content: $ti-icon-ball-tennis;
}
.#{$ti-icon-prefix}-ball-volleyball:before {
    content: $ti-icon-ball-volleyball;
}
.#{$ti-icon-prefix}-ban:before {
    content: $ti-icon-ban;
}
.#{$ti-icon-prefix}-bandage:before {
    content: $ti-icon-bandage;
}
.#{$ti-icon-prefix}-barcode:before {
    content: $ti-icon-barcode;
}
.#{$ti-icon-prefix}-basket:before {
    content: $ti-icon-basket;
}
.#{$ti-icon-prefix}-battery:before {
    content: $ti-icon-battery;
}
.#{$ti-icon-prefix}-battery-1:before {
    content: $ti-icon-battery-1;
}
.#{$ti-icon-prefix}-battery-2:before {
    content: $ti-icon-battery-2;
}
.#{$ti-icon-prefix}-battery-3:before {
    content: $ti-icon-battery-3;
}
.#{$ti-icon-prefix}-battery-4:before {
    content: $ti-icon-battery-4;
}
.#{$ti-icon-prefix}-battery-automotive:before {
    content: $ti-icon-battery-automotive;
}
.#{$ti-icon-prefix}-battery-charging:before {
    content: $ti-icon-battery-charging;
}
.#{$ti-icon-prefix}-battery-off:before {
    content: $ti-icon-battery-off;
}
.#{$ti-icon-prefix}-bed:before {
    content: $ti-icon-bed;
}
.#{$ti-icon-prefix}-bell:before {
    content: $ti-icon-bell;
}
.#{$ti-icon-prefix}-bell-minus:before {
    content: $ti-icon-bell-minus;
}
.#{$ti-icon-prefix}-bell-off:before {
    content: $ti-icon-bell-off;
}
.#{$ti-icon-prefix}-bell-plus:before {
    content: $ti-icon-bell-plus;
}
.#{$ti-icon-prefix}-bell-ringing:before {
    content: $ti-icon-bell-ringing;
}
.#{$ti-icon-prefix}-bell-ringing-2:before {
    content: $ti-icon-bell-ringing-2;
}
.#{$ti-icon-prefix}-bell-x:before {
    content: $ti-icon-bell-x;
}
.#{$ti-icon-prefix}-bike:before {
    content: $ti-icon-bike;
}
.#{$ti-icon-prefix}-binary:before {
    content: $ti-icon-binary;
}
.#{$ti-icon-prefix}-biohazard:before {
    content: $ti-icon-biohazard;
}
.#{$ti-icon-prefix}-blockquote:before {
    content: $ti-icon-blockquote;
}
.#{$ti-icon-prefix}-bluetooth:before {
    content: $ti-icon-bluetooth;
}
.#{$ti-icon-prefix}-bluetooth-connected:before {
    content: $ti-icon-bluetooth-connected;
}
.#{$ti-icon-prefix}-bluetooth-off:before {
    content: $ti-icon-bluetooth-off;
}
.#{$ti-icon-prefix}-bold:before {
    content: $ti-icon-bold;
}
.#{$ti-icon-prefix}-bolt:before {
    content: $ti-icon-bolt;
}
.#{$ti-icon-prefix}-bolt-off:before {
    content: $ti-icon-bolt-off;
}
.#{$ti-icon-prefix}-bone:before {
    content: $ti-icon-bone;
}
.#{$ti-icon-prefix}-book:before {
    content: $ti-icon-book;
}
.#{$ti-icon-prefix}-bookmark:before {
    content: $ti-icon-bookmark;
}
.#{$ti-icon-prefix}-bookmark-off:before {
    content: $ti-icon-bookmark-off;
}
.#{$ti-icon-prefix}-bookmarks:before {
    content: $ti-icon-bookmarks;
}
.#{$ti-icon-prefix}-border-all:before {
    content: $ti-icon-border-all;
}
.#{$ti-icon-prefix}-border-bottom:before {
    content: $ti-icon-border-bottom;
}
.#{$ti-icon-prefix}-border-horizontal:before {
    content: $ti-icon-border-horizontal;
}
.#{$ti-icon-prefix}-border-inner:before {
    content: $ti-icon-border-inner;
}
.#{$ti-icon-prefix}-border-left:before {
    content: $ti-icon-border-left;
}
.#{$ti-icon-prefix}-border-none:before {
    content: $ti-icon-border-none;
}
.#{$ti-icon-prefix}-border-outer:before {
    content: $ti-icon-border-outer;
}
.#{$ti-icon-prefix}-border-radius:before {
    content: $ti-icon-border-radius;
}
.#{$ti-icon-prefix}-border-right:before {
    content: $ti-icon-border-right;
}
.#{$ti-icon-prefix}-border-style:before {
    content: $ti-icon-border-style;
}
.#{$ti-icon-prefix}-border-top:before {
    content: $ti-icon-border-top;
}
.#{$ti-icon-prefix}-border-vertical:before {
    content: $ti-icon-border-vertical;
}
.#{$ti-icon-prefix}-box:before {
    content: $ti-icon-box;
}
.#{$ti-icon-prefix}-box-margin:before {
    content: $ti-icon-box-margin;
}
.#{$ti-icon-prefix}-box-model:before {
    content: $ti-icon-box-model;
}
.#{$ti-icon-prefix}-box-multiple:before {
    content: $ti-icon-box-multiple;
}
.#{$ti-icon-prefix}-box-multiple-0:before {
    content: $ti-icon-box-multiple-0;
}
.#{$ti-icon-prefix}-box-multiple-1:before {
    content: $ti-icon-box-multiple-1;
}
.#{$ti-icon-prefix}-box-multiple-2:before {
    content: $ti-icon-box-multiple-2;
}
.#{$ti-icon-prefix}-box-multiple-3:before {
    content: $ti-icon-box-multiple-3;
}
.#{$ti-icon-prefix}-box-multiple-4:before {
    content: $ti-icon-box-multiple-4;
}
.#{$ti-icon-prefix}-box-multiple-5:before {
    content: $ti-icon-box-multiple-5;
}
.#{$ti-icon-prefix}-box-multiple-6:before {
    content: $ti-icon-box-multiple-6;
}
.#{$ti-icon-prefix}-box-multiple-7:before {
    content: $ti-icon-box-multiple-7;
}
.#{$ti-icon-prefix}-box-multiple-8:before {
    content: $ti-icon-box-multiple-8;
}
.#{$ti-icon-prefix}-box-multiple-9:before {
    content: $ti-icon-box-multiple-9;
}
.#{$ti-icon-prefix}-box-padding:before {
    content: $ti-icon-box-padding;
}
.#{$ti-icon-prefix}-braces:before {
    content: $ti-icon-braces;
}
.#{$ti-icon-prefix}-brackets:before {
    content: $ti-icon-brackets;
}
.#{$ti-icon-prefix}-brand-airbnb:before {
    content: $ti-icon-brand-airbnb;
}
.#{$ti-icon-prefix}-brand-android:before {
    content: $ti-icon-brand-android;
}
.#{$ti-icon-prefix}-brand-apple:before {
    content: $ti-icon-brand-apple;
}
.#{$ti-icon-prefix}-brand-apple-arcade:before {
    content: $ti-icon-brand-apple-arcade;
}
.#{$ti-icon-prefix}-brand-appstore:before {
    content: $ti-icon-brand-appstore;
}
.#{$ti-icon-prefix}-brand-asana:before {
    content: $ti-icon-brand-asana;
}
.#{$ti-icon-prefix}-brand-behance:before {
    content: $ti-icon-brand-behance;
}
.#{$ti-icon-prefix}-brand-bing:before {
    content: $ti-icon-brand-bing;
}
.#{$ti-icon-prefix}-brand-bitbucket:before {
    content: $ti-icon-brand-bitbucket;
}
.#{$ti-icon-prefix}-brand-booking:before {
    content: $ti-icon-brand-booking;
}
.#{$ti-icon-prefix}-brand-chrome:before {
    content: $ti-icon-brand-chrome;
}
.#{$ti-icon-prefix}-brand-codepen:before {
    content: $ti-icon-brand-codepen;
}
.#{$ti-icon-prefix}-brand-codesandbox:before {
    content: $ti-icon-brand-codesandbox;
}
.#{$ti-icon-prefix}-brand-css3:before {
    content: $ti-icon-brand-css3;
}
.#{$ti-icon-prefix}-brand-deviantart:before {
    content: $ti-icon-brand-deviantart;
}
.#{$ti-icon-prefix}-brand-discord:before {
    content: $ti-icon-brand-discord;
}
.#{$ti-icon-prefix}-brand-disqus:before {
    content: $ti-icon-brand-disqus;
}
.#{$ti-icon-prefix}-brand-docker:before {
    content: $ti-icon-brand-docker;
}
.#{$ti-icon-prefix}-brand-dribbble:before {
    content: $ti-icon-brand-dribbble;
}
.#{$ti-icon-prefix}-brand-edge:before {
    content: $ti-icon-brand-edge;
}
.#{$ti-icon-prefix}-brand-facebook:before {
    content: $ti-icon-brand-facebook;
}
.#{$ti-icon-prefix}-brand-figma:before {
    content: $ti-icon-brand-figma;
}
.#{$ti-icon-prefix}-brand-firefox:before {
    content: $ti-icon-brand-firefox;
}
.#{$ti-icon-prefix}-brand-flickr:before {
    content: $ti-icon-brand-flickr;
}
.#{$ti-icon-prefix}-brand-foursquare:before {
    content: $ti-icon-brand-foursquare;
}
.#{$ti-icon-prefix}-brand-framer:before {
    content: $ti-icon-brand-framer;
}
.#{$ti-icon-prefix}-brand-github:before {
    content: $ti-icon-brand-github;
}
.#{$ti-icon-prefix}-brand-gitlab:before {
    content: $ti-icon-brand-gitlab;
}
.#{$ti-icon-prefix}-brand-google:before {
    content: $ti-icon-brand-google;
}
.#{$ti-icon-prefix}-brand-google-analytics:before {
    content: $ti-icon-brand-google-analytics;
}
.#{$ti-icon-prefix}-brand-google-drive:before {
    content: $ti-icon-brand-google-drive;
}
.#{$ti-icon-prefix}-brand-google-play:before {
    content: $ti-icon-brand-google-play;
}
.#{$ti-icon-prefix}-brand-gravatar:before {
    content: $ti-icon-brand-gravatar;
}
.#{$ti-icon-prefix}-brand-hipchat:before {
    content: $ti-icon-brand-hipchat;
}
.#{$ti-icon-prefix}-brand-html5:before {
    content: $ti-icon-brand-html5;
}
.#{$ti-icon-prefix}-brand-instagram:before {
    content: $ti-icon-brand-instagram;
}
.#{$ti-icon-prefix}-brand-kickstarter:before {
    content: $ti-icon-brand-kickstarter;
}
.#{$ti-icon-prefix}-brand-kotlin:before {
    content: $ti-icon-brand-kotlin;
}
.#{$ti-icon-prefix}-brand-linkedin:before {
    content: $ti-icon-brand-linkedin;
}
.#{$ti-icon-prefix}-brand-medium:before {
    content: $ti-icon-brand-medium;
}
.#{$ti-icon-prefix}-brand-messenger:before {
    content: $ti-icon-brand-messenger;
}
.#{$ti-icon-prefix}-brand-netflix:before {
    content: $ti-icon-brand-netflix;
}
.#{$ti-icon-prefix}-brand-open-source:before {
    content: $ti-icon-brand-open-source;
}
.#{$ti-icon-prefix}-brand-opera:before {
    content: $ti-icon-brand-opera;
}
.#{$ti-icon-prefix}-brand-pagekit:before {
    content: $ti-icon-brand-pagekit;
}
.#{$ti-icon-prefix}-brand-patreon:before {
    content: $ti-icon-brand-patreon;
}
.#{$ti-icon-prefix}-brand-paypal:before {
    content: $ti-icon-brand-paypal;
}
.#{$ti-icon-prefix}-brand-pinterest:before {
    content: $ti-icon-brand-pinterest;
}
.#{$ti-icon-prefix}-brand-pocket:before {
    content: $ti-icon-brand-pocket;
}
.#{$ti-icon-prefix}-brand-producthunt:before {
    content: $ti-icon-brand-producthunt;
}
.#{$ti-icon-prefix}-brand-python:before {
    content: $ti-icon-brand-python;
}
.#{$ti-icon-prefix}-brand-reddit:before {
    content: $ti-icon-brand-reddit;
}
.#{$ti-icon-prefix}-brand-safari:before {
    content: $ti-icon-brand-safari;
}
.#{$ti-icon-prefix}-brand-sass:before {
    content: $ti-icon-brand-sass;
}
.#{$ti-icon-prefix}-brand-sentry:before {
    content: $ti-icon-brand-sentry;
}
.#{$ti-icon-prefix}-brand-shazam:before {
    content: $ti-icon-brand-shazam;
}
.#{$ti-icon-prefix}-brand-sketch:before {
    content: $ti-icon-brand-sketch;
}
.#{$ti-icon-prefix}-brand-skype:before {
    content: $ti-icon-brand-skype;
}
.#{$ti-icon-prefix}-brand-slack:before {
    content: $ti-icon-brand-slack;
}
.#{$ti-icon-prefix}-brand-snapchat:before {
    content: $ti-icon-brand-snapchat;
}
.#{$ti-icon-prefix}-brand-soundcloud:before {
    content: $ti-icon-brand-soundcloud;
}
.#{$ti-icon-prefix}-brand-spotify:before {
    content: $ti-icon-brand-spotify;
}
.#{$ti-icon-prefix}-brand-steam:before {
    content: $ti-icon-brand-steam;
}
.#{$ti-icon-prefix}-brand-stripe:before {
    content: $ti-icon-brand-stripe;
}
.#{$ti-icon-prefix}-brand-tabler:before {
    content: $ti-icon-brand-tabler;
}
.#{$ti-icon-prefix}-brand-tailwind:before {
    content: $ti-icon-brand-tailwind;
}
.#{$ti-icon-prefix}-brand-telegram:before {
    content: $ti-icon-brand-telegram;
}
.#{$ti-icon-prefix}-brand-tidal:before {
    content: $ti-icon-brand-tidal;
}
.#{$ti-icon-prefix}-brand-tiktok:before {
    content: $ti-icon-brand-tiktok;
}
.#{$ti-icon-prefix}-brand-tinder:before {
    content: $ti-icon-brand-tinder;
}
.#{$ti-icon-prefix}-brand-tumblr:before {
    content: $ti-icon-brand-tumblr;
}
.#{$ti-icon-prefix}-brand-twitch:before {
    content: $ti-icon-brand-twitch;
}
.#{$ti-icon-prefix}-brand-twitter:before {
    content: $ti-icon-brand-twitter;
}
.#{$ti-icon-prefix}-brand-unsplash:before {
    content: $ti-icon-brand-unsplash;
}
.#{$ti-icon-prefix}-brand-vimeo:before {
    content: $ti-icon-brand-vimeo;
}
.#{$ti-icon-prefix}-brand-vk:before {
    content: $ti-icon-brand-vk;
}
.#{$ti-icon-prefix}-brand-whatsapp:before {
    content: $ti-icon-brand-whatsapp;
}
.#{$ti-icon-prefix}-brand-windows:before {
    content: $ti-icon-brand-windows;
}
.#{$ti-icon-prefix}-brand-yahoo:before {
    content: $ti-icon-brand-yahoo;
}
.#{$ti-icon-prefix}-brand-ycombinator:before {
    content: $ti-icon-brand-ycombinator;
}
.#{$ti-icon-prefix}-brand-youtube:before {
    content: $ti-icon-brand-youtube;
}
.#{$ti-icon-prefix}-briefcase:before {
    content: $ti-icon-briefcase;
}
.#{$ti-icon-prefix}-brightness:before {
    content: $ti-icon-brightness;
}
.#{$ti-icon-prefix}-brightness-2:before {
    content: $ti-icon-brightness-2;
}
.#{$ti-icon-prefix}-brightness-down:before {
    content: $ti-icon-brightness-down;
}
.#{$ti-icon-prefix}-brightness-half:before {
    content: $ti-icon-brightness-half;
}
.#{$ti-icon-prefix}-brightness-up:before {
    content: $ti-icon-brightness-up;
}
.#{$ti-icon-prefix}-browser:before {
    content: $ti-icon-browser;
}
.#{$ti-icon-prefix}-brush:before {
    content: $ti-icon-brush;
}
.#{$ti-icon-prefix}-bucket:before {
    content: $ti-icon-bucket;
}
.#{$ti-icon-prefix}-bug:before {
    content: $ti-icon-bug;
}
.#{$ti-icon-prefix}-building:before {
    content: $ti-icon-building;
}
.#{$ti-icon-prefix}-building-arch:before {
    content: $ti-icon-building-arch;
}
.#{$ti-icon-prefix}-building-bank:before {
    content: $ti-icon-building-bank;
}
.#{$ti-icon-prefix}-building-bridge:before {
    content: $ti-icon-building-bridge;
}
.#{$ti-icon-prefix}-building-bridge-2:before {
    content: $ti-icon-building-bridge-2;
}
.#{$ti-icon-prefix}-building-carousel:before {
    content: $ti-icon-building-carousel;
}
.#{$ti-icon-prefix}-building-castle:before {
    content: $ti-icon-building-castle;
}
.#{$ti-icon-prefix}-building-church:before {
    content: $ti-icon-building-church;
}
.#{$ti-icon-prefix}-building-community:before {
    content: $ti-icon-building-community;
}
.#{$ti-icon-prefix}-building-cottage:before {
    content: $ti-icon-building-cottage;
}
.#{$ti-icon-prefix}-building-factory:before {
    content: $ti-icon-building-factory;
}
.#{$ti-icon-prefix}-building-fortress:before {
    content: $ti-icon-building-fortress;
}
.#{$ti-icon-prefix}-building-hospital:before {
    content: $ti-icon-building-hospital;
}
.#{$ti-icon-prefix}-building-lighthouse:before {
    content: $ti-icon-building-lighthouse;
}
.#{$ti-icon-prefix}-building-monument:before {
    content: $ti-icon-building-monument;
}
.#{$ti-icon-prefix}-building-pavilon:before {
    content: $ti-icon-building-pavilon;
}
.#{$ti-icon-prefix}-building-skyscraper:before {
    content: $ti-icon-building-skyscraper;
}
.#{$ti-icon-prefix}-building-store:before {
    content: $ti-icon-building-store;
}
.#{$ti-icon-prefix}-building-warehouse:before {
    content: $ti-icon-building-warehouse;
}
.#{$ti-icon-prefix}-bulb:before {
    content: $ti-icon-bulb;
}
.#{$ti-icon-prefix}-bulb-off:before {
    content: $ti-icon-bulb-off;
}
.#{$ti-icon-prefix}-bulldozer:before {
    content: $ti-icon-bulldozer;
}
.#{$ti-icon-prefix}-bus:before {
    content: $ti-icon-bus;
}
.#{$ti-icon-prefix}-businessplan:before {
    content: $ti-icon-businessplan;
}
.#{$ti-icon-prefix}-calculator:before {
    content: $ti-icon-calculator;
}
.#{$ti-icon-prefix}-calendar:before {
    content: $ti-icon-calendar;
}
.#{$ti-icon-prefix}-calendar-event:before {
    content: $ti-icon-calendar-event;
}
.#{$ti-icon-prefix}-calendar-minus:before {
    content: $ti-icon-calendar-minus;
}
.#{$ti-icon-prefix}-calendar-off:before {
    content: $ti-icon-calendar-off;
}
.#{$ti-icon-prefix}-calendar-plus:before {
    content: $ti-icon-calendar-plus;
}
.#{$ti-icon-prefix}-calendar-stats:before {
    content: $ti-icon-calendar-stats;
}
.#{$ti-icon-prefix}-calendar-time:before {
    content: $ti-icon-calendar-time;
}
.#{$ti-icon-prefix}-camera:before {
    content: $ti-icon-camera;
}
.#{$ti-icon-prefix}-camera-minus:before {
    content: $ti-icon-camera-minus;
}
.#{$ti-icon-prefix}-camera-off:before {
    content: $ti-icon-camera-off;
}
.#{$ti-icon-prefix}-camera-plus:before {
    content: $ti-icon-camera-plus;
}
.#{$ti-icon-prefix}-camera-rotate:before {
    content: $ti-icon-camera-rotate;
}
.#{$ti-icon-prefix}-camera-selfie:before {
    content: $ti-icon-camera-selfie;
}
.#{$ti-icon-prefix}-capture:before {
    content: $ti-icon-capture;
}
.#{$ti-icon-prefix}-car:before {
    content: $ti-icon-car;
}
.#{$ti-icon-prefix}-caravan:before {
    content: $ti-icon-caravan;
}
.#{$ti-icon-prefix}-cardboards:before {
    content: $ti-icon-cardboards;
}
.#{$ti-icon-prefix}-caret-down:before {
    content: $ti-icon-caret-down;
}
.#{$ti-icon-prefix}-caret-left:before {
    content: $ti-icon-caret-left;
}
.#{$ti-icon-prefix}-caret-right:before {
    content: $ti-icon-caret-right;
}
.#{$ti-icon-prefix}-caret-up:before {
    content: $ti-icon-caret-up;
}
.#{$ti-icon-prefix}-cash:before {
    content: $ti-icon-cash;
}
.#{$ti-icon-prefix}-cash-banknote:before {
    content: $ti-icon-cash-banknote;
}
.#{$ti-icon-prefix}-cash-banknote-off:before {
    content: $ti-icon-cash-banknote-off;
}
.#{$ti-icon-prefix}-cast:before {
    content: $ti-icon-cast;
}
.#{$ti-icon-prefix}-ce:before {
    content: $ti-icon-ce;
}
.#{$ti-icon-prefix}-certificate:before {
    content: $ti-icon-certificate;
}
.#{$ti-icon-prefix}-charging-pile:before {
    content: $ti-icon-charging-pile;
}
.#{$ti-icon-prefix}-chart-arcs:before {
    content: $ti-icon-chart-arcs;
}
.#{$ti-icon-prefix}-chart-arcs-3:before {
    content: $ti-icon-chart-arcs-3;
}
.#{$ti-icon-prefix}-chart-area:before {
    content: $ti-icon-chart-area;
}
.#{$ti-icon-prefix}-chart-area-line:before {
    content: $ti-icon-chart-area-line;
}
.#{$ti-icon-prefix}-chart-arrows:before {
    content: $ti-icon-chart-arrows;
}
.#{$ti-icon-prefix}-chart-arrows-vertical:before {
    content: $ti-icon-chart-arrows-vertical;
}
.#{$ti-icon-prefix}-chart-bar:before {
    content: $ti-icon-chart-bar;
}
.#{$ti-icon-prefix}-chart-bubble:before {
    content: $ti-icon-chart-bubble;
}
.#{$ti-icon-prefix}-chart-candle:before {
    content: $ti-icon-chart-candle;
}
.#{$ti-icon-prefix}-chart-circles:before {
    content: $ti-icon-chart-circles;
}
.#{$ti-icon-prefix}-chart-donut:before {
    content: $ti-icon-chart-donut;
}
.#{$ti-icon-prefix}-chart-donut-2:before {
    content: $ti-icon-chart-donut-2;
}
.#{$ti-icon-prefix}-chart-donut-3:before {
    content: $ti-icon-chart-donut-3;
}
.#{$ti-icon-prefix}-chart-donut-4:before {
    content: $ti-icon-chart-donut-4;
}
.#{$ti-icon-prefix}-chart-dots:before {
    content: $ti-icon-chart-dots;
}
.#{$ti-icon-prefix}-chart-infographic:before {
    content: $ti-icon-chart-infographic;
}
.#{$ti-icon-prefix}-chart-line:before {
    content: $ti-icon-chart-line;
}
.#{$ti-icon-prefix}-chart-pie:before {
    content: $ti-icon-chart-pie;
}
.#{$ti-icon-prefix}-chart-pie-2:before {
    content: $ti-icon-chart-pie-2;
}
.#{$ti-icon-prefix}-chart-pie-3:before {
    content: $ti-icon-chart-pie-3;
}
.#{$ti-icon-prefix}-chart-pie-4:before {
    content: $ti-icon-chart-pie-4;
}
.#{$ti-icon-prefix}-chart-radar:before {
    content: $ti-icon-chart-radar;
}
.#{$ti-icon-prefix}-check:before {
    content: $ti-icon-check;
}
.#{$ti-icon-prefix}-checkbox:before {
    content: $ti-icon-checkbox;
}
.#{$ti-icon-prefix}-checks:before {
    content: $ti-icon-checks;
}
.#{$ti-icon-prefix}-chevron-down:before {
    content: $ti-icon-chevron-down;
}
.#{$ti-icon-prefix}-chevron-down-left:before {
    content: $ti-icon-chevron-down-left;
}
.#{$ti-icon-prefix}-chevron-down-right:before {
    content: $ti-icon-chevron-down-right;
}
.#{$ti-icon-prefix}-chevron-left:before {
    content: $ti-icon-chevron-left;
}
.#{$ti-icon-prefix}-chevron-right:before {
    content: $ti-icon-chevron-right;
}
.#{$ti-icon-prefix}-chevron-up:before {
    content: $ti-icon-chevron-up;
}
.#{$ti-icon-prefix}-chevron-up-left:before {
    content: $ti-icon-chevron-up-left;
}
.#{$ti-icon-prefix}-chevron-up-right:before {
    content: $ti-icon-chevron-up-right;
}
.#{$ti-icon-prefix}-chevrons-down:before {
    content: $ti-icon-chevrons-down;
}
.#{$ti-icon-prefix}-chevrons-down-left:before {
    content: $ti-icon-chevrons-down-left;
}
.#{$ti-icon-prefix}-chevrons-down-right:before {
    content: $ti-icon-chevrons-down-right;
}
.#{$ti-icon-prefix}-chevrons-left:before {
    content: $ti-icon-chevrons-left;
}
.#{$ti-icon-prefix}-chevrons-right:before {
    content: $ti-icon-chevrons-right;
}
.#{$ti-icon-prefix}-chevrons-up:before {
    content: $ti-icon-chevrons-up;
}
.#{$ti-icon-prefix}-chevrons-up-left:before {
    content: $ti-icon-chevrons-up-left;
}
.#{$ti-icon-prefix}-chevrons-up-right:before {
    content: $ti-icon-chevrons-up-right;
}
.#{$ti-icon-prefix}-christmas-tree:before {
    content: $ti-icon-christmas-tree;
}
.#{$ti-icon-prefix}-circle:before {
    content: $ti-icon-circle;
}
.#{$ti-icon-prefix}-circle-0:before {
    content: $ti-icon-circle-0;
}
.#{$ti-icon-prefix}-circle-1:before {
    content: $ti-icon-circle-1;
}
.#{$ti-icon-prefix}-circle-2:before {
    content: $ti-icon-circle-2;
}
.#{$ti-icon-prefix}-circle-3:before {
    content: $ti-icon-circle-3;
}
.#{$ti-icon-prefix}-circle-4:before {
    content: $ti-icon-circle-4;
}
.#{$ti-icon-prefix}-circle-5:before {
    content: $ti-icon-circle-5;
}
.#{$ti-icon-prefix}-circle-6:before {
    content: $ti-icon-circle-6;
}
.#{$ti-icon-prefix}-circle-7:before {
    content: $ti-icon-circle-7;
}
.#{$ti-icon-prefix}-circle-8:before {
    content: $ti-icon-circle-8;
}
.#{$ti-icon-prefix}-circle-9:before {
    content: $ti-icon-circle-9;
}
.#{$ti-icon-prefix}-circle-check:before {
    content: $ti-icon-circle-check;
}
.#{$ti-icon-prefix}-circle-dashed:before {
    content: $ti-icon-circle-dashed;
}
.#{$ti-icon-prefix}-circle-dotted:before {
    content: $ti-icon-circle-dotted;
}
.#{$ti-icon-prefix}-circle-half:before {
    content: $ti-icon-circle-half;
}
.#{$ti-icon-prefix}-circle-half-vertical:before {
    content: $ti-icon-circle-half-vertical;
}
.#{$ti-icon-prefix}-circle-minus:before {
    content: $ti-icon-circle-minus;
}
.#{$ti-icon-prefix}-circle-off:before {
    content: $ti-icon-circle-off;
}
.#{$ti-icon-prefix}-circle-plus:before {
    content: $ti-icon-circle-plus;
}
.#{$ti-icon-prefix}-circle-square:before {
    content: $ti-icon-circle-square;
}
.#{$ti-icon-prefix}-circle-x:before {
    content: $ti-icon-circle-x;
}
.#{$ti-icon-prefix}-circles:before {
    content: $ti-icon-circles;
}
.#{$ti-icon-prefix}-clear-all:before {
    content: $ti-icon-clear-all;
}
.#{$ti-icon-prefix}-clear-formatting:before {
    content: $ti-icon-clear-formatting;
}
.#{$ti-icon-prefix}-click:before {
    content: $ti-icon-click;
}
.#{$ti-icon-prefix}-clipboard:before {
    content: $ti-icon-clipboard;
}
.#{$ti-icon-prefix}-clipboard-check:before {
    content: $ti-icon-clipboard-check;
}
.#{$ti-icon-prefix}-clipboard-list:before {
    content: $ti-icon-clipboard-list;
}
.#{$ti-icon-prefix}-clipboard-x:before {
    content: $ti-icon-clipboard-x;
}
.#{$ti-icon-prefix}-clock:before {
    content: $ti-icon-clock;
}
.#{$ti-icon-prefix}-cloud:before {
    content: $ti-icon-cloud;
}
.#{$ti-icon-prefix}-cloud-download:before {
    content: $ti-icon-cloud-download;
}
.#{$ti-icon-prefix}-cloud-fog:before {
    content: $ti-icon-cloud-fog;
}
.#{$ti-icon-prefix}-cloud-off:before {
    content: $ti-icon-cloud-off;
}
.#{$ti-icon-prefix}-cloud-rain:before {
    content: $ti-icon-cloud-rain;
}
.#{$ti-icon-prefix}-cloud-snow:before {
    content: $ti-icon-cloud-snow;
}
.#{$ti-icon-prefix}-cloud-storm:before {
    content: $ti-icon-cloud-storm;
}
.#{$ti-icon-prefix}-cloud-upload:before {
    content: $ti-icon-cloud-upload;
}
.#{$ti-icon-prefix}-code:before {
    content: $ti-icon-code;
}
.#{$ti-icon-prefix}-code-minus:before {
    content: $ti-icon-code-minus;
}
.#{$ti-icon-prefix}-code-plus:before {
    content: $ti-icon-code-plus;
}
.#{$ti-icon-prefix}-coin:before {
    content: $ti-icon-coin;
}
.#{$ti-icon-prefix}-color-picker:before {
    content: $ti-icon-color-picker;
}
.#{$ti-icon-prefix}-color-swatch:before {
    content: $ti-icon-color-swatch;
}
.#{$ti-icon-prefix}-column-insert-left:before {
    content: $ti-icon-column-insert-left;
}
.#{$ti-icon-prefix}-column-insert-right:before {
    content: $ti-icon-column-insert-right;
}
.#{$ti-icon-prefix}-columns:before {
    content: $ti-icon-columns;
}
.#{$ti-icon-prefix}-comet:before {
    content: $ti-icon-comet;
}
.#{$ti-icon-prefix}-command:before {
    content: $ti-icon-command;
}
.#{$ti-icon-prefix}-compass:before {
    content: $ti-icon-compass;
}
.#{$ti-icon-prefix}-confetti:before {
    content: $ti-icon-confetti;
}
.#{$ti-icon-prefix}-container:before {
    content: $ti-icon-container;
}
.#{$ti-icon-prefix}-contrast:before {
    content: $ti-icon-contrast;
}
.#{$ti-icon-prefix}-copy:before {
    content: $ti-icon-copy;
}
.#{$ti-icon-prefix}-copyleft:before {
    content: $ti-icon-copyleft;
}
.#{$ti-icon-prefix}-copyright:before {
    content: $ti-icon-copyright;
}
.#{$ti-icon-prefix}-corner-down-left:before {
    content: $ti-icon-corner-down-left;
}
.#{$ti-icon-prefix}-corner-down-left-double:before {
    content: $ti-icon-corner-down-left-double;
}
.#{$ti-icon-prefix}-corner-down-right:before {
    content: $ti-icon-corner-down-right;
}
.#{$ti-icon-prefix}-corner-down-right-double:before {
    content: $ti-icon-corner-down-right-double;
}
.#{$ti-icon-prefix}-corner-left-down:before {
    content: $ti-icon-corner-left-down;
}
.#{$ti-icon-prefix}-corner-left-down-double:before {
    content: $ti-icon-corner-left-down-double;
}
.#{$ti-icon-prefix}-corner-left-up:before {
    content: $ti-icon-corner-left-up;
}
.#{$ti-icon-prefix}-corner-left-up-double:before {
    content: $ti-icon-corner-left-up-double;
}
.#{$ti-icon-prefix}-corner-right-down:before {
    content: $ti-icon-corner-right-down;
}
.#{$ti-icon-prefix}-corner-right-down-double:before {
    content: $ti-icon-corner-right-down-double;
}
.#{$ti-icon-prefix}-corner-right-up:before {
    content: $ti-icon-corner-right-up;
}
.#{$ti-icon-prefix}-corner-right-up-double:before {
    content: $ti-icon-corner-right-up-double;
}
.#{$ti-icon-prefix}-corner-up-left:before {
    content: $ti-icon-corner-up-left;
}
.#{$ti-icon-prefix}-corner-up-left-double:before {
    content: $ti-icon-corner-up-left-double;
}
.#{$ti-icon-prefix}-corner-up-right:before {
    content: $ti-icon-corner-up-right;
}
.#{$ti-icon-prefix}-corner-up-right-double:before {
    content: $ti-icon-corner-up-right-double;
}
.#{$ti-icon-prefix}-credit-card:before {
    content: $ti-icon-credit-card;
}
.#{$ti-icon-prefix}-credit-card-off:before {
    content: $ti-icon-credit-card-off;
}
.#{$ti-icon-prefix}-crop:before {
    content: $ti-icon-crop;
}
.#{$ti-icon-prefix}-crosshair:before {
    content: $ti-icon-crosshair;
}
.#{$ti-icon-prefix}-crown:before {
    content: $ti-icon-crown;
}
.#{$ti-icon-prefix}-crown-off:before {
    content: $ti-icon-crown-off;
}
.#{$ti-icon-prefix}-curly-loop:before {
    content: $ti-icon-curly-loop;
}
.#{$ti-icon-prefix}-currency-bahraini:before {
    content: $ti-icon-currency-bahraini;
}
.#{$ti-icon-prefix}-currency-bath:before {
    content: $ti-icon-currency-bath;
}
.#{$ti-icon-prefix}-currency-bitcoin:before {
    content: $ti-icon-currency-bitcoin;
}
.#{$ti-icon-prefix}-currency-cent:before {
    content: $ti-icon-currency-cent;
}
.#{$ti-icon-prefix}-currency-dinar:before {
    content: $ti-icon-currency-dinar;
}
.#{$ti-icon-prefix}-currency-dirham:before {
    content: $ti-icon-currency-dirham;
}
.#{$ti-icon-prefix}-currency-dollar:before {
    content: $ti-icon-currency-dollar;
}
.#{$ti-icon-prefix}-currency-dollar-australian:before {
    content: $ti-icon-currency-dollar-australian;
}
.#{$ti-icon-prefix}-currency-dollar-canadian:before {
    content: $ti-icon-currency-dollar-canadian;
}
.#{$ti-icon-prefix}-currency-dollar-singapore:before {
    content: $ti-icon-currency-dollar-singapore;
}
.#{$ti-icon-prefix}-currency-ethereum:before {
    content: $ti-icon-currency-ethereum;
}
.#{$ti-icon-prefix}-currency-euro:before {
    content: $ti-icon-currency-euro;
}
.#{$ti-icon-prefix}-currency-forint:before {
    content: $ti-icon-currency-forint;
}
.#{$ti-icon-prefix}-currency-frank:before {
    content: $ti-icon-currency-frank;
}
.#{$ti-icon-prefix}-currency-krone-czech:before {
    content: $ti-icon-currency-krone-czech;
}
.#{$ti-icon-prefix}-currency-krone-danish:before {
    content: $ti-icon-currency-krone-danish;
}
.#{$ti-icon-prefix}-currency-krone-swedish:before {
    content: $ti-icon-currency-krone-swedish;
}
.#{$ti-icon-prefix}-currency-leu:before {
    content: $ti-icon-currency-leu;
}
.#{$ti-icon-prefix}-currency-lira:before {
    content: $ti-icon-currency-lira;
}
.#{$ti-icon-prefix}-currency-litecoin:before {
    content: $ti-icon-currency-litecoin;
}
.#{$ti-icon-prefix}-currency-naira:before {
    content: $ti-icon-currency-naira;
}
.#{$ti-icon-prefix}-currency-pound:before {
    content: $ti-icon-currency-pound;
}
.#{$ti-icon-prefix}-currency-real:before {
    content: $ti-icon-currency-real;
}
.#{$ti-icon-prefix}-currency-renminbi:before {
    content: $ti-icon-currency-renminbi;
}
.#{$ti-icon-prefix}-currency-ripple:before {
    content: $ti-icon-currency-ripple;
}
.#{$ti-icon-prefix}-currency-riyal:before {
    content: $ti-icon-currency-riyal;
}
.#{$ti-icon-prefix}-currency-rubel:before {
    content: $ti-icon-currency-rubel;
}
.#{$ti-icon-prefix}-currency-rupee:before {
    content: $ti-icon-currency-rupee;
}
.#{$ti-icon-prefix}-currency-shekel:before {
    content: $ti-icon-currency-shekel;
}
.#{$ti-icon-prefix}-currency-taka:before {
    content: $ti-icon-currency-taka;
}
.#{$ti-icon-prefix}-currency-tugrik:before {
    content: $ti-icon-currency-tugrik;
}
.#{$ti-icon-prefix}-currency-won:before {
    content: $ti-icon-currency-won;
}
.#{$ti-icon-prefix}-currency-yen:before {
    content: $ti-icon-currency-yen;
}
.#{$ti-icon-prefix}-currency-zloty:before {
    content: $ti-icon-currency-zloty;
}
.#{$ti-icon-prefix}-current-location:before {
    content: $ti-icon-current-location;
}
.#{$ti-icon-prefix}-cursor-text:before {
    content: $ti-icon-cursor-text;
}
.#{$ti-icon-prefix}-cut:before {
    content: $ti-icon-cut;
}
.#{$ti-icon-prefix}-dashboard:before {
    content: $ti-icon-dashboard;
}
.#{$ti-icon-prefix}-database:before {
    content: $ti-icon-database;
}
.#{$ti-icon-prefix}-database-export:before {
    content: $ti-icon-database-export;
}
.#{$ti-icon-prefix}-database-import:before {
    content: $ti-icon-database-import;
}
.#{$ti-icon-prefix}-database-off:before {
    content: $ti-icon-database-off;
}
.#{$ti-icon-prefix}-details:before {
    content: $ti-icon-details;
}
.#{$ti-icon-prefix}-device-analytics:before {
    content: $ti-icon-device-analytics;
}
.#{$ti-icon-prefix}-device-audio-tape:before {
    content: $ti-icon-device-audio-tape;
}
.#{$ti-icon-prefix}-device-cctv:before {
    content: $ti-icon-device-cctv;
}
.#{$ti-icon-prefix}-device-computer-camera:before {
    content: $ti-icon-device-computer-camera;
}
.#{$ti-icon-prefix}-device-computer-camera-off:before {
    content: $ti-icon-device-computer-camera-off;
}
.#{$ti-icon-prefix}-device-desktop:before {
    content: $ti-icon-device-desktop;
}
.#{$ti-icon-prefix}-device-desktop-analytics:before {
    content: $ti-icon-device-desktop-analytics;
}
.#{$ti-icon-prefix}-device-desktop-off:before {
    content: $ti-icon-device-desktop-off;
}
.#{$ti-icon-prefix}-device-floppy:before {
    content: $ti-icon-device-floppy;
}
.#{$ti-icon-prefix}-device-gamepad:before {
    content: $ti-icon-device-gamepad;
}
.#{$ti-icon-prefix}-device-laptop:before {
    content: $ti-icon-device-laptop;
}
.#{$ti-icon-prefix}-device-mobile:before {
    content: $ti-icon-device-mobile;
}
.#{$ti-icon-prefix}-device-mobile-message:before {
    content: $ti-icon-device-mobile-message;
}
.#{$ti-icon-prefix}-device-mobile-rotated:before {
    content: $ti-icon-device-mobile-rotated;
}
.#{$ti-icon-prefix}-device-mobile-vibration:before {
    content: $ti-icon-device-mobile-vibration;
}
.#{$ti-icon-prefix}-device-speaker:before {
    content: $ti-icon-device-speaker;
}
.#{$ti-icon-prefix}-device-tablet:before {
    content: $ti-icon-device-tablet;
}
.#{$ti-icon-prefix}-device-tv:before {
    content: $ti-icon-device-tv;
}
.#{$ti-icon-prefix}-device-watch:before {
    content: $ti-icon-device-watch;
}
.#{$ti-icon-prefix}-devices:before {
    content: $ti-icon-devices;
}
.#{$ti-icon-prefix}-devices-2:before {
    content: $ti-icon-devices-2;
}
.#{$ti-icon-prefix}-devices-pc:before {
    content: $ti-icon-devices-pc;
}
.#{$ti-icon-prefix}-diamond:before {
    content: $ti-icon-diamond;
}
.#{$ti-icon-prefix}-dice:before {
    content: $ti-icon-dice;
}
.#{$ti-icon-prefix}-dimensions:before {
    content: $ti-icon-dimensions;
}
.#{$ti-icon-prefix}-direction:before {
    content: $ti-icon-direction;
}
.#{$ti-icon-prefix}-direction-horizontal:before {
    content: $ti-icon-direction-horizontal;
}
.#{$ti-icon-prefix}-directions:before {
    content: $ti-icon-directions;
}
.#{$ti-icon-prefix}-disabled:before {
    content: $ti-icon-disabled;
}
.#{$ti-icon-prefix}-disabled-2:before {
    content: $ti-icon-disabled-2;
}
.#{$ti-icon-prefix}-disc:before {
    content: $ti-icon-disc;
}
.#{$ti-icon-prefix}-discount:before {
    content: $ti-icon-discount;
}
.#{$ti-icon-prefix}-discount-2:before {
    content: $ti-icon-discount-2;
}
.#{$ti-icon-prefix}-divide:before {
    content: $ti-icon-divide;
}
.#{$ti-icon-prefix}-dna:before {
    content: $ti-icon-dna;
}
.#{$ti-icon-prefix}-dots:before {
    content: $ti-icon-dots;
}
.#{$ti-icon-prefix}-dots-circle-horizontal:before {
    content: $ti-icon-dots-circle-horizontal;
}
.#{$ti-icon-prefix}-dots-diagonal:before {
    content: $ti-icon-dots-diagonal;
}
.#{$ti-icon-prefix}-dots-diagonal-2:before {
    content: $ti-icon-dots-diagonal-2;
}
.#{$ti-icon-prefix}-dots-vertical:before {
    content: $ti-icon-dots-vertical;
}
.#{$ti-icon-prefix}-download:before {
    content: $ti-icon-download;
}
.#{$ti-icon-prefix}-drag-drop:before {
    content: $ti-icon-drag-drop;
}
.#{$ti-icon-prefix}-drag-drop-2:before {
    content: $ti-icon-drag-drop-2;
}
.#{$ti-icon-prefix}-drone:before {
    content: $ti-icon-drone;
}
.#{$ti-icon-prefix}-drone-off:before {
    content: $ti-icon-drone-off;
}
.#{$ti-icon-prefix}-droplet:before {
    content: $ti-icon-droplet;
}
.#{$ti-icon-prefix}-droplet-filled:before {
    content: $ti-icon-droplet-filled;
}
.#{$ti-icon-prefix}-droplet-filled-2:before {
    content: $ti-icon-droplet-filled-2;
}
.#{$ti-icon-prefix}-droplet-half:before {
    content: $ti-icon-droplet-half;
}
.#{$ti-icon-prefix}-droplet-half-2:before {
    content: $ti-icon-droplet-half-2;
}
.#{$ti-icon-prefix}-droplet-off:before {
    content: $ti-icon-droplet-off;
}
.#{$ti-icon-prefix}-ear:before {
    content: $ti-icon-ear;
}
.#{$ti-icon-prefix}-ear-off:before {
    content: $ti-icon-ear-off;
}
.#{$ti-icon-prefix}-edit:before {
    content: $ti-icon-edit;
}
.#{$ti-icon-prefix}-edit-circle:before {
    content: $ti-icon-edit-circle;
}
.#{$ti-icon-prefix}-egg:before {
    content: $ti-icon-egg;
}
.#{$ti-icon-prefix}-emphasis:before {
    content: $ti-icon-emphasis;
}
.#{$ti-icon-prefix}-equal:before {
    content: $ti-icon-equal;
}
.#{$ti-icon-prefix}-equal-not:before {
    content: $ti-icon-equal-not;
}
.#{$ti-icon-prefix}-eraser:before {
    content: $ti-icon-eraser;
}
.#{$ti-icon-prefix}-exchange:before {
    content: $ti-icon-exchange;
}
.#{$ti-icon-prefix}-exposure:before {
    content: $ti-icon-exposure;
}
.#{$ti-icon-prefix}-external-link:before {
    content: $ti-icon-external-link;
}
.#{$ti-icon-prefix}-eye:before {
    content: $ti-icon-eye;
}
.#{$ti-icon-prefix}-eye-check:before {
    content: $ti-icon-eye-check;
}
.#{$ti-icon-prefix}-eye-off:before {
    content: $ti-icon-eye-off;
}
.#{$ti-icon-prefix}-eyeglass:before {
    content: $ti-icon-eyeglass;
}
.#{$ti-icon-prefix}-eyeglass-2:before {
    content: $ti-icon-eyeglass-2;
}
.#{$ti-icon-prefix}-face-id:before {
    content: $ti-icon-face-id;
}
.#{$ti-icon-prefix}-fall:before {
    content: $ti-icon-fall;
}
.#{$ti-icon-prefix}-feather:before {
    content: $ti-icon-feather;
}
.#{$ti-icon-prefix}-file:before {
    content: $ti-icon-file;
}
.#{$ti-icon-prefix}-file-alert:before {
    content: $ti-icon-file-alert;
}
.#{$ti-icon-prefix}-file-analytics:before {
    content: $ti-icon-file-analytics;
}
.#{$ti-icon-prefix}-file-certificate:before {
    content: $ti-icon-file-certificate;
}
.#{$ti-icon-prefix}-file-check:before {
    content: $ti-icon-file-check;
}
.#{$ti-icon-prefix}-file-code:before {
    content: $ti-icon-file-code;
}
.#{$ti-icon-prefix}-file-code-2:before {
    content: $ti-icon-file-code-2;
}
.#{$ti-icon-prefix}-file-diff:before {
    content: $ti-icon-file-diff;
}
.#{$ti-icon-prefix}-file-dislike:before {
    content: $ti-icon-file-dislike;
}
.#{$ti-icon-prefix}-file-download:before {
    content: $ti-icon-file-download;
}
.#{$ti-icon-prefix}-file-export:before {
    content: $ti-icon-file-export;
}
.#{$ti-icon-prefix}-file-horizontal:before {
    content: $ti-icon-file-horizontal;
}
.#{$ti-icon-prefix}-file-import:before {
    content: $ti-icon-file-import;
}
.#{$ti-icon-prefix}-file-info:before {
    content: $ti-icon-file-info;
}
.#{$ti-icon-prefix}-file-invoice:before {
    content: $ti-icon-file-invoice;
}
.#{$ti-icon-prefix}-file-like:before {
    content: $ti-icon-file-like;
}
.#{$ti-icon-prefix}-file-minus:before {
    content: $ti-icon-file-minus;
}
.#{$ti-icon-prefix}-file-music:before {
    content: $ti-icon-file-music;
}
.#{$ti-icon-prefix}-file-off:before {
    content: $ti-icon-file-off;
}
.#{$ti-icon-prefix}-file-phone:before {
    content: $ti-icon-file-phone;
}
.#{$ti-icon-prefix}-file-plus:before {
    content: $ti-icon-file-plus;
}
.#{$ti-icon-prefix}-file-report:before {
    content: $ti-icon-file-report;
}
.#{$ti-icon-prefix}-file-search:before {
    content: $ti-icon-file-search;
}
.#{$ti-icon-prefix}-file-shredder:before {
    content: $ti-icon-file-shredder;
}
.#{$ti-icon-prefix}-file-symlink:before {
    content: $ti-icon-file-symlink;
}
.#{$ti-icon-prefix}-file-text:before {
    content: $ti-icon-file-text;
}
.#{$ti-icon-prefix}-file-upload:before {
    content: $ti-icon-file-upload;
}
.#{$ti-icon-prefix}-file-x:before {
    content: $ti-icon-file-x;
}
.#{$ti-icon-prefix}-file-zip:before {
    content: $ti-icon-file-zip;
}
.#{$ti-icon-prefix}-files:before {
    content: $ti-icon-files;
}
.#{$ti-icon-prefix}-files-off:before {
    content: $ti-icon-files-off;
}
.#{$ti-icon-prefix}-filter:before {
    content: $ti-icon-filter;
}
.#{$ti-icon-prefix}-filter-off:before {
    content: $ti-icon-filter-off;
}
.#{$ti-icon-prefix}-fingerprint:before {
    content: $ti-icon-fingerprint;
}
.#{$ti-icon-prefix}-firetruck:before {
    content: $ti-icon-firetruck;
}
.#{$ti-icon-prefix}-flag:before {
    content: $ti-icon-flag;
}
.#{$ti-icon-prefix}-flag-2:before {
    content: $ti-icon-flag-2;
}
.#{$ti-icon-prefix}-flag-3:before {
    content: $ti-icon-flag-3;
}
.#{$ti-icon-prefix}-flame:before {
    content: $ti-icon-flame;
}
.#{$ti-icon-prefix}-flare:before {
    content: $ti-icon-flare;
}
.#{$ti-icon-prefix}-flask:before {
    content: $ti-icon-flask;
}
.#{$ti-icon-prefix}-flip-horizontal:before {
    content: $ti-icon-flip-horizontal;
}
.#{$ti-icon-prefix}-flip-vertical:before {
    content: $ti-icon-flip-vertical;
}
.#{$ti-icon-prefix}-float-center:before {
    content: $ti-icon-float-center;
}
.#{$ti-icon-prefix}-float-left:before {
    content: $ti-icon-float-left;
}
.#{$ti-icon-prefix}-float-none:before {
    content: $ti-icon-float-none;
}
.#{$ti-icon-prefix}-float-right:before {
    content: $ti-icon-float-right;
}
.#{$ti-icon-prefix}-focus:before {
    content: $ti-icon-focus;
}
.#{$ti-icon-prefix}-focus-2:before {
    content: $ti-icon-focus-2;
}
.#{$ti-icon-prefix}-fold:before {
    content: $ti-icon-fold;
}
.#{$ti-icon-prefix}-fold-down:before {
    content: $ti-icon-fold-down;
}
.#{$ti-icon-prefix}-fold-up:before {
    content: $ti-icon-fold-up;
}
.#{$ti-icon-prefix}-folder:before {
    content: $ti-icon-folder;
}
.#{$ti-icon-prefix}-folder-minus:before {
    content: $ti-icon-folder-minus;
}
.#{$ti-icon-prefix}-folder-off:before {
    content: $ti-icon-folder-off;
}
.#{$ti-icon-prefix}-folder-plus:before {
    content: $ti-icon-folder-plus;
}
.#{$ti-icon-prefix}-folder-x:before {
    content: $ti-icon-folder-x;
}
.#{$ti-icon-prefix}-folders:before {
    content: $ti-icon-folders;
}
.#{$ti-icon-prefix}-forbid:before {
    content: $ti-icon-forbid;
}
.#{$ti-icon-prefix}-forbid-2:before {
    content: $ti-icon-forbid-2;
}
.#{$ti-icon-prefix}-forklift:before {
    content: $ti-icon-forklift;
}
.#{$ti-icon-prefix}-forms:before {
    content: $ti-icon-forms;
}
.#{$ti-icon-prefix}-frame:before {
    content: $ti-icon-frame;
}
.#{$ti-icon-prefix}-friends:before {
    content: $ti-icon-friends;
}
.#{$ti-icon-prefix}-gas-station:before {
    content: $ti-icon-gas-station;
}
.#{$ti-icon-prefix}-gauge:before {
    content: $ti-icon-gauge;
}
.#{$ti-icon-prefix}-geometry:before {
    content: $ti-icon-geometry;
}
.#{$ti-icon-prefix}-ghost:before {
    content: $ti-icon-ghost;
}
.#{$ti-icon-prefix}-gift:before {
    content: $ti-icon-gift;
}
.#{$ti-icon-prefix}-git-branch:before {
    content: $ti-icon-git-branch;
}
.#{$ti-icon-prefix}-git-commit:before {
    content: $ti-icon-git-commit;
}
.#{$ti-icon-prefix}-git-compare:before {
    content: $ti-icon-git-compare;
}
.#{$ti-icon-prefix}-git-fork:before {
    content: $ti-icon-git-fork;
}
.#{$ti-icon-prefix}-git-merge:before {
    content: $ti-icon-git-merge;
}
.#{$ti-icon-prefix}-git-pull-request:before {
    content: $ti-icon-git-pull-request;
}
.#{$ti-icon-prefix}-glass:before {
    content: $ti-icon-glass;
}
.#{$ti-icon-prefix}-glass-full:before {
    content: $ti-icon-glass-full;
}
.#{$ti-icon-prefix}-glass-off:before {
    content: $ti-icon-glass-off;
}
.#{$ti-icon-prefix}-globe:before {
    content: $ti-icon-globe;
}
.#{$ti-icon-prefix}-golf:before {
    content: $ti-icon-golf;
}
.#{$ti-icon-prefix}-gps:before {
    content: $ti-icon-gps;
}
.#{$ti-icon-prefix}-grain:before {
    content: $ti-icon-grain;
}
.#{$ti-icon-prefix}-grid-dots:before {
    content: $ti-icon-grid-dots;
}
.#{$ti-icon-prefix}-grip-horizontal:before {
    content: $ti-icon-grip-horizontal;
}
.#{$ti-icon-prefix}-grip-vertical:before {
    content: $ti-icon-grip-vertical;
}
.#{$ti-icon-prefix}-growth:before {
    content: $ti-icon-growth;
}
.#{$ti-icon-prefix}-h-1:before {
    content: $ti-icon-h-1;
}
.#{$ti-icon-prefix}-h-2:before {
    content: $ti-icon-h-2;
}
.#{$ti-icon-prefix}-h-3:before {
    content: $ti-icon-h-3;
}
.#{$ti-icon-prefix}-h-4:before {
    content: $ti-icon-h-4;
}
.#{$ti-icon-prefix}-h-5:before {
    content: $ti-icon-h-5;
}
.#{$ti-icon-prefix}-h-6:before {
    content: $ti-icon-h-6;
}
.#{$ti-icon-prefix}-hand-finger:before {
    content: $ti-icon-hand-finger;
}
.#{$ti-icon-prefix}-hand-little-finger:before {
    content: $ti-icon-hand-little-finger;
}
.#{$ti-icon-prefix}-hand-middle-finger:before {
    content: $ti-icon-hand-middle-finger;
}
.#{$ti-icon-prefix}-hand-off:before {
    content: $ti-icon-hand-off;
}
.#{$ti-icon-prefix}-hand-ring-finger:before {
    content: $ti-icon-hand-ring-finger;
}
.#{$ti-icon-prefix}-hand-rock:before {
    content: $ti-icon-hand-rock;
}
.#{$ti-icon-prefix}-hand-stop:before {
    content: $ti-icon-hand-stop;
}
.#{$ti-icon-prefix}-hand-three-fingers:before {
    content: $ti-icon-hand-three-fingers;
}
.#{$ti-icon-prefix}-hand-two-fingers:before {
    content: $ti-icon-hand-two-fingers;
}
.#{$ti-icon-prefix}-hanger:before {
    content: $ti-icon-hanger;
}
.#{$ti-icon-prefix}-hash:before {
    content: $ti-icon-hash;
}
.#{$ti-icon-prefix}-heading:before {
    content: $ti-icon-heading;
}
.#{$ti-icon-prefix}-headphones:before {
    content: $ti-icon-headphones;
}
.#{$ti-icon-prefix}-headphones-off:before {
    content: $ti-icon-headphones-off;
}
.#{$ti-icon-prefix}-headset:before {
    content: $ti-icon-headset;
}
.#{$ti-icon-prefix}-heart:before {
    content: $ti-icon-heart;
}
.#{$ti-icon-prefix}-heart-broken:before {
    content: $ti-icon-heart-broken;
}
.#{$ti-icon-prefix}-helicopter:before {
    content: $ti-icon-helicopter;
}
.#{$ti-icon-prefix}-helicopter-landing:before {
    content: $ti-icon-helicopter-landing;
}
.#{$ti-icon-prefix}-help:before {
    content: $ti-icon-help;
}
.#{$ti-icon-prefix}-hexagon:before {
    content: $ti-icon-hexagon;
}
.#{$ti-icon-prefix}-hexagon-off:before {
    content: $ti-icon-hexagon-off;
}
.#{$ti-icon-prefix}-hierarchy:before {
    content: $ti-icon-hierarchy;
}
.#{$ti-icon-prefix}-hierarchy-2:before {
    content: $ti-icon-hierarchy-2;
}
.#{$ti-icon-prefix}-history:before {
    content: $ti-icon-history;
}
.#{$ti-icon-prefix}-home:before {
    content: $ti-icon-home;
}
.#{$ti-icon-prefix}-home-2:before {
    content: $ti-icon-home-2;
}
.#{$ti-icon-prefix}-ice-cream:before {
    content: $ti-icon-ice-cream;
}
.#{$ti-icon-prefix}-ice-cream-2:before {
    content: $ti-icon-ice-cream-2;
}
.#{$ti-icon-prefix}-id:before {
    content: $ti-icon-id;
}
.#{$ti-icon-prefix}-inbox:before {
    content: $ti-icon-inbox;
}
.#{$ti-icon-prefix}-indent-decrease:before {
    content: $ti-icon-indent-decrease;
}
.#{$ti-icon-prefix}-indent-increase:before {
    content: $ti-icon-indent-increase;
}
.#{$ti-icon-prefix}-infinity:before {
    content: $ti-icon-infinity;
}
.#{$ti-icon-prefix}-info-circle:before {
    content: $ti-icon-info-circle;
}
.#{$ti-icon-prefix}-info-square:before {
    content: $ti-icon-info-square;
}
.#{$ti-icon-prefix}-italic:before {
    content: $ti-icon-italic;
}
.#{$ti-icon-prefix}-jump-rope:before {
    content: $ti-icon-jump-rope;
}
.#{$ti-icon-prefix}-karate:before {
    content: $ti-icon-karate;
}
.#{$ti-icon-prefix}-key:before {
    content: $ti-icon-key;
}
.#{$ti-icon-prefix}-keyboard:before {
    content: $ti-icon-keyboard;
}
.#{$ti-icon-prefix}-keyboard-hide:before {
    content: $ti-icon-keyboard-hide;
}
.#{$ti-icon-prefix}-keyboard-off:before {
    content: $ti-icon-keyboard-off;
}
.#{$ti-icon-prefix}-keyboard-show:before {
    content: $ti-icon-keyboard-show;
}
.#{$ti-icon-prefix}-language:before {
    content: $ti-icon-language;
}
.#{$ti-icon-prefix}-layers-difference:before {
    content: $ti-icon-layers-difference;
}
.#{$ti-icon-prefix}-layers-intersect:before {
    content: $ti-icon-layers-intersect;
}
.#{$ti-icon-prefix}-layers-linked:before {
    content: $ti-icon-layers-linked;
}
.#{$ti-icon-prefix}-layers-subtract:before {
    content: $ti-icon-layers-subtract;
}
.#{$ti-icon-prefix}-layers-union:before {
    content: $ti-icon-layers-union;
}
.#{$ti-icon-prefix}-layout:before {
    content: $ti-icon-layout;
}
.#{$ti-icon-prefix}-layout-2:before {
    content: $ti-icon-layout-2;
}
.#{$ti-icon-prefix}-layout-align-bottom:before {
    content: $ti-icon-layout-align-bottom;
}
.#{$ti-icon-prefix}-layout-align-center:before {
    content: $ti-icon-layout-align-center;
}
.#{$ti-icon-prefix}-layout-align-left:before {
    content: $ti-icon-layout-align-left;
}
.#{$ti-icon-prefix}-layout-align-middle:before {
    content: $ti-icon-layout-align-middle;
}
.#{$ti-icon-prefix}-layout-align-right:before {
    content: $ti-icon-layout-align-right;
}
.#{$ti-icon-prefix}-layout-align-top:before {
    content: $ti-icon-layout-align-top;
}
.#{$ti-icon-prefix}-layout-bottombar:before {
    content: $ti-icon-layout-bottombar;
}
.#{$ti-icon-prefix}-layout-cards:before {
    content: $ti-icon-layout-cards;
}
.#{$ti-icon-prefix}-layout-columns:before {
    content: $ti-icon-layout-columns;
}
.#{$ti-icon-prefix}-layout-distribute-horizontal:before {
    content: $ti-icon-layout-distribute-horizontal;
}
.#{$ti-icon-prefix}-layout-distribute-vertical:before {
    content: $ti-icon-layout-distribute-vertical;
}
.#{$ti-icon-prefix}-layout-grid:before {
    content: $ti-icon-layout-grid;
}
.#{$ti-icon-prefix}-layout-grid-add:before {
    content: $ti-icon-layout-grid-add;
}
.#{$ti-icon-prefix}-layout-kanban:before {
    content: $ti-icon-layout-kanban;
}
.#{$ti-icon-prefix}-layout-list:before {
    content: $ti-icon-layout-list;
}
.#{$ti-icon-prefix}-layout-navbar:before {
    content: $ti-icon-layout-navbar;
}
.#{$ti-icon-prefix}-layout-rows:before {
    content: $ti-icon-layout-rows;
}
.#{$ti-icon-prefix}-layout-sidebar:before {
    content: $ti-icon-layout-sidebar;
}
.#{$ti-icon-prefix}-layout-sidebar-right:before {
    content: $ti-icon-layout-sidebar-right;
}
.#{$ti-icon-prefix}-leaf:before {
    content: $ti-icon-leaf;
}
.#{$ti-icon-prefix}-lego:before {
    content: $ti-icon-lego;
}
.#{$ti-icon-prefix}-letter-a:before {
    content: $ti-icon-letter-a;
}
.#{$ti-icon-prefix}-letter-b:before {
    content: $ti-icon-letter-b;
}
.#{$ti-icon-prefix}-letter-c:before {
    content: $ti-icon-letter-c;
}
.#{$ti-icon-prefix}-letter-case:before {
    content: $ti-icon-letter-case;
}
.#{$ti-icon-prefix}-letter-case-lower:before {
    content: $ti-icon-letter-case-lower;
}
.#{$ti-icon-prefix}-letter-case-toggle:before {
    content: $ti-icon-letter-case-toggle;
}
.#{$ti-icon-prefix}-letter-case-upper:before {
    content: $ti-icon-letter-case-upper;
}
.#{$ti-icon-prefix}-letter-d:before {
    content: $ti-icon-letter-d;
}
.#{$ti-icon-prefix}-letter-e:before {
    content: $ti-icon-letter-e;
}
.#{$ti-icon-prefix}-letter-f:before {
    content: $ti-icon-letter-f;
}
.#{$ti-icon-prefix}-letter-g:before {
    content: $ti-icon-letter-g;
}
.#{$ti-icon-prefix}-letter-h:before {
    content: $ti-icon-letter-h;
}
.#{$ti-icon-prefix}-letter-i:before {
    content: $ti-icon-letter-i;
}
.#{$ti-icon-prefix}-letter-j:before {
    content: $ti-icon-letter-j;
}
.#{$ti-icon-prefix}-letter-k:before {
    content: $ti-icon-letter-k;
}
.#{$ti-icon-prefix}-letter-l:before {
    content: $ti-icon-letter-l;
}
.#{$ti-icon-prefix}-letter-m:before {
    content: $ti-icon-letter-m;
}
.#{$ti-icon-prefix}-letter-n:before {
    content: $ti-icon-letter-n;
}
.#{$ti-icon-prefix}-letter-o:before {
    content: $ti-icon-letter-o;
}
.#{$ti-icon-prefix}-letter-p:before {
    content: $ti-icon-letter-p;
}
.#{$ti-icon-prefix}-letter-q:before {
    content: $ti-icon-letter-q;
}
.#{$ti-icon-prefix}-letter-r:before {
    content: $ti-icon-letter-r;
}
.#{$ti-icon-prefix}-letter-s:before {
    content: $ti-icon-letter-s;
}
.#{$ti-icon-prefix}-letter-spacing:before {
    content: $ti-icon-letter-spacing;
}
.#{$ti-icon-prefix}-letter-t:before {
    content: $ti-icon-letter-t;
}
.#{$ti-icon-prefix}-letter-u:before {
    content: $ti-icon-letter-u;
}
.#{$ti-icon-prefix}-letter-v:before {
    content: $ti-icon-letter-v;
}
.#{$ti-icon-prefix}-letter-w:before {
    content: $ti-icon-letter-w;
}
.#{$ti-icon-prefix}-letter-x:before {
    content: $ti-icon-letter-x;
}
.#{$ti-icon-prefix}-letter-y:before {
    content: $ti-icon-letter-y;
}
.#{$ti-icon-prefix}-letter-z:before {
    content: $ti-icon-letter-z;
}
.#{$ti-icon-prefix}-letters-case:before {
    content: $ti-icon-letters-case;
}
.#{$ti-icon-prefix}-license:before {
    content: $ti-icon-license;
}
.#{$ti-icon-prefix}-lifebuoy:before {
    content: $ti-icon-lifebuoy;
}
.#{$ti-icon-prefix}-line:before {
    content: $ti-icon-line;
}
.#{$ti-icon-prefix}-line-dashed:before {
    content: $ti-icon-line-dashed;
}
.#{$ti-icon-prefix}-line-dotted:before {
    content: $ti-icon-line-dotted;
}
.#{$ti-icon-prefix}-line-height:before {
    content: $ti-icon-line-height;
}
.#{$ti-icon-prefix}-link:before {
    content: $ti-icon-link;
}
.#{$ti-icon-prefix}-list:before {
    content: $ti-icon-list;
}
.#{$ti-icon-prefix}-list-check:before {
    content: $ti-icon-list-check;
}
.#{$ti-icon-prefix}-list-search:before {
    content: $ti-icon-list-search;
}
.#{$ti-icon-prefix}-live-photo:before {
    content: $ti-icon-live-photo;
}
.#{$ti-icon-prefix}-live-view:before {
    content: $ti-icon-live-view;
}
.#{$ti-icon-prefix}-loader:before {
    content: $ti-icon-loader;
}
.#{$ti-icon-prefix}-loader-quarter:before {
    content: $ti-icon-loader-quarter;
}
.#{$ti-icon-prefix}-location:before {
    content: $ti-icon-location;
}
.#{$ti-icon-prefix}-lock:before {
    content: $ti-icon-lock;
}
.#{$ti-icon-prefix}-lock-access:before {
    content: $ti-icon-lock-access;
}
.#{$ti-icon-prefix}-lock-off:before {
    content: $ti-icon-lock-off;
}
.#{$ti-icon-prefix}-lock-open:before {
    content: $ti-icon-lock-open;
}
.#{$ti-icon-prefix}-login:before {
    content: $ti-icon-login;
}
.#{$ti-icon-prefix}-logout:before {
    content: $ti-icon-logout;
}
.#{$ti-icon-prefix}-macro:before {
    content: $ti-icon-macro;
}
.#{$ti-icon-prefix}-magnet:before {
    content: $ti-icon-magnet;
}
.#{$ti-icon-prefix}-mail:before {
    content: $ti-icon-mail;
}
.#{$ti-icon-prefix}-mail-forward:before {
    content: $ti-icon-mail-forward;
}
.#{$ti-icon-prefix}-mail-opened:before {
    content: $ti-icon-mail-opened;
}
.#{$ti-icon-prefix}-mailbox:before {
    content: $ti-icon-mailbox;
}
.#{$ti-icon-prefix}-man:before {
    content: $ti-icon-man;
}
.#{$ti-icon-prefix}-manual-gearbox:before {
    content: $ti-icon-manual-gearbox;
}
.#{$ti-icon-prefix}-map:before {
    content: $ti-icon-map;
}
.#{$ti-icon-prefix}-map-2:before {
    content: $ti-icon-map-2;
}
.#{$ti-icon-prefix}-map-pin:before {
    content: $ti-icon-map-pin;
}
.#{$ti-icon-prefix}-map-pin-off:before {
    content: $ti-icon-map-pin-off;
}
.#{$ti-icon-prefix}-map-pins:before {
    content: $ti-icon-map-pins;
}
.#{$ti-icon-prefix}-markdown:before {
    content: $ti-icon-markdown;
}
.#{$ti-icon-prefix}-marquee:before {
    content: $ti-icon-marquee;
}
.#{$ti-icon-prefix}-marquee-2:before {
    content: $ti-icon-marquee-2;
}
.#{$ti-icon-prefix}-mars:before {
    content: $ti-icon-mars;
}
.#{$ti-icon-prefix}-mask:before {
    content: $ti-icon-mask;
}
.#{$ti-icon-prefix}-mask-off:before {
    content: $ti-icon-mask-off;
}
.#{$ti-icon-prefix}-massage:before {
    content: $ti-icon-massage;
}
.#{$ti-icon-prefix}-math:before {
    content: $ti-icon-math;
}
.#{$ti-icon-prefix}-math-function:before {
    content: $ti-icon-math-function;
}
.#{$ti-icon-prefix}-math-symbols:before {
    content: $ti-icon-math-symbols;
}
.#{$ti-icon-prefix}-maximize:before {
    content: $ti-icon-maximize;
}
.#{$ti-icon-prefix}-medal:before {
    content: $ti-icon-medal;
}
.#{$ti-icon-prefix}-medical-cross:before {
    content: $ti-icon-medical-cross;
}
.#{$ti-icon-prefix}-menu:before {
    content: $ti-icon-menu;
}
.#{$ti-icon-prefix}-menu-2:before {
    content: $ti-icon-menu-2;
}
.#{$ti-icon-prefix}-message:before {
    content: $ti-icon-message;
}
.#{$ti-icon-prefix}-message-2:before {
    content: $ti-icon-message-2;
}
.#{$ti-icon-prefix}-message-circle:before {
    content: $ti-icon-message-circle;
}
.#{$ti-icon-prefix}-message-circle-2:before {
    content: $ti-icon-message-circle-2;
}
.#{$ti-icon-prefix}-message-circle-off:before {
    content: $ti-icon-message-circle-off;
}
.#{$ti-icon-prefix}-message-dots:before {
    content: $ti-icon-message-dots;
}
.#{$ti-icon-prefix}-message-off:before {
    content: $ti-icon-message-off;
}
.#{$ti-icon-prefix}-message-plus:before {
    content: $ti-icon-message-plus;
}
.#{$ti-icon-prefix}-message-report:before {
    content: $ti-icon-message-report;
}
.#{$ti-icon-prefix}-messages:before {
    content: $ti-icon-messages;
}
.#{$ti-icon-prefix}-messages-off:before {
    content: $ti-icon-messages-off;
}
.#{$ti-icon-prefix}-microphone:before {
    content: $ti-icon-microphone;
}
.#{$ti-icon-prefix}-microphone-off:before {
    content: $ti-icon-microphone-off;
}
.#{$ti-icon-prefix}-minimize:before {
    content: $ti-icon-minimize;
}
.#{$ti-icon-prefix}-minus:before {
    content: $ti-icon-minus;
}
.#{$ti-icon-prefix}-minus-vertical:before {
    content: $ti-icon-minus-vertical;
}
.#{$ti-icon-prefix}-mist:before {
    content: $ti-icon-mist;
}
.#{$ti-icon-prefix}-mood-boy:before {
    content: $ti-icon-mood-boy;
}
.#{$ti-icon-prefix}-mood-confuzed:before {
    content: $ti-icon-mood-confuzed;
}
.#{$ti-icon-prefix}-mood-crazy-happy:before {
    content: $ti-icon-mood-crazy-happy;
}
.#{$ti-icon-prefix}-mood-cry:before {
    content: $ti-icon-mood-cry;
}
.#{$ti-icon-prefix}-mood-empty:before {
    content: $ti-icon-mood-empty;
}
.#{$ti-icon-prefix}-mood-happy:before {
    content: $ti-icon-mood-happy;
}
.#{$ti-icon-prefix}-mood-kid:before {
    content: $ti-icon-mood-kid;
}
.#{$ti-icon-prefix}-mood-neutral:before {
    content: $ti-icon-mood-neutral;
}
.#{$ti-icon-prefix}-mood-sad:before {
    content: $ti-icon-mood-sad;
}
.#{$ti-icon-prefix}-mood-smile:before {
    content: $ti-icon-mood-smile;
}
.#{$ti-icon-prefix}-mood-suprised:before {
    content: $ti-icon-mood-suprised;
}
.#{$ti-icon-prefix}-mood-tongue:before {
    content: $ti-icon-mood-tongue;
}
.#{$ti-icon-prefix}-moon:before {
    content: $ti-icon-moon;
}
.#{$ti-icon-prefix}-moon-2:before {
    content: $ti-icon-moon-2;
}
.#{$ti-icon-prefix}-moon-stars:before {
    content: $ti-icon-moon-stars;
}
.#{$ti-icon-prefix}-moped:before {
    content: $ti-icon-moped;
}
.#{$ti-icon-prefix}-motorbike:before {
    content: $ti-icon-motorbike;
}
.#{$ti-icon-prefix}-mouse:before {
    content: $ti-icon-mouse;
}
.#{$ti-icon-prefix}-movie:before {
    content: $ti-icon-movie;
}
.#{$ti-icon-prefix}-mug:before {
    content: $ti-icon-mug;
}
.#{$ti-icon-prefix}-music:before {
    content: $ti-icon-music;
}
.#{$ti-icon-prefix}-new-section:before {
    content: $ti-icon-new-section;
}
.#{$ti-icon-prefix}-news:before {
    content: $ti-icon-news;
}
.#{$ti-icon-prefix}-nfc:before {
    content: $ti-icon-nfc;
}
.#{$ti-icon-prefix}-note:before {
    content: $ti-icon-note;
}
.#{$ti-icon-prefix}-notebook:before {
    content: $ti-icon-notebook;
}
.#{$ti-icon-prefix}-notes:before {
    content: $ti-icon-notes;
}
.#{$ti-icon-prefix}-notification:before {
    content: $ti-icon-notification;
}
.#{$ti-icon-prefix}-number-0:before {
    content: $ti-icon-number-0;
}
.#{$ti-icon-prefix}-number-1:before {
    content: $ti-icon-number-1;
}
.#{$ti-icon-prefix}-number-2:before {
    content: $ti-icon-number-2;
}
.#{$ti-icon-prefix}-number-3:before {
    content: $ti-icon-number-3;
}
.#{$ti-icon-prefix}-number-4:before {
    content: $ti-icon-number-4;
}
.#{$ti-icon-prefix}-number-5:before {
    content: $ti-icon-number-5;
}
.#{$ti-icon-prefix}-number-6:before {
    content: $ti-icon-number-6;
}
.#{$ti-icon-prefix}-number-7:before {
    content: $ti-icon-number-7;
}
.#{$ti-icon-prefix}-number-8:before {
    content: $ti-icon-number-8;
}
.#{$ti-icon-prefix}-number-9:before {
    content: $ti-icon-number-9;
}
.#{$ti-icon-prefix}-octagon:before {
    content: $ti-icon-octagon;
}
.#{$ti-icon-prefix}-octagon-off:before {
    content: $ti-icon-octagon-off;
}
.#{$ti-icon-prefix}-old:before {
    content: $ti-icon-old;
}
.#{$ti-icon-prefix}-olympics:before {
    content: $ti-icon-olympics;
}
.#{$ti-icon-prefix}-omega:before {
    content: $ti-icon-omega;
}
.#{$ti-icon-prefix}-outlet:before {
    content: $ti-icon-outlet;
}
.#{$ti-icon-prefix}-overline:before {
    content: $ti-icon-overline;
}
.#{$ti-icon-prefix}-package:before {
    content: $ti-icon-package;
}
.#{$ti-icon-prefix}-pacman:before {
    content: $ti-icon-pacman;
}
.#{$ti-icon-prefix}-page-break:before {
    content: $ti-icon-page-break;
}
.#{$ti-icon-prefix}-paint:before {
    content: $ti-icon-paint;
}
.#{$ti-icon-prefix}-palette:before {
    content: $ti-icon-palette;
}
.#{$ti-icon-prefix}-panorama-horizontal:before {
    content: $ti-icon-panorama-horizontal;
}
.#{$ti-icon-prefix}-panorama-vertical:before {
    content: $ti-icon-panorama-vertical;
}
.#{$ti-icon-prefix}-paperclip:before {
    content: $ti-icon-paperclip;
}
.#{$ti-icon-prefix}-parachute:before {
    content: $ti-icon-parachute;
}
.#{$ti-icon-prefix}-parentheses:before {
    content: $ti-icon-parentheses;
}
.#{$ti-icon-prefix}-parking:before {
    content: $ti-icon-parking;
}
.#{$ti-icon-prefix}-peace:before {
    content: $ti-icon-peace;
}
.#{$ti-icon-prefix}-pencil:before {
    content: $ti-icon-pencil;
}
.#{$ti-icon-prefix}-pennant:before {
    content: $ti-icon-pennant;
}
.#{$ti-icon-prefix}-percentage:before {
    content: $ti-icon-percentage;
}
.#{$ti-icon-prefix}-perspective:before {
    content: $ti-icon-perspective;
}
.#{$ti-icon-prefix}-phone:before {
    content: $ti-icon-phone;
}
.#{$ti-icon-prefix}-phone-call:before {
    content: $ti-icon-phone-call;
}
.#{$ti-icon-prefix}-phone-calling:before {
    content: $ti-icon-phone-calling;
}
.#{$ti-icon-prefix}-phone-check:before {
    content: $ti-icon-phone-check;
}
.#{$ti-icon-prefix}-phone-incoming:before {
    content: $ti-icon-phone-incoming;
}
.#{$ti-icon-prefix}-phone-off:before {
    content: $ti-icon-phone-off;
}
.#{$ti-icon-prefix}-phone-outgoing:before {
    content: $ti-icon-phone-outgoing;
}
.#{$ti-icon-prefix}-phone-pause:before {
    content: $ti-icon-phone-pause;
}
.#{$ti-icon-prefix}-phone-plus:before {
    content: $ti-icon-phone-plus;
}
.#{$ti-icon-prefix}-phone-x:before {
    content: $ti-icon-phone-x;
}
.#{$ti-icon-prefix}-photo:before {
    content: $ti-icon-photo;
}
.#{$ti-icon-prefix}-photo-off:before {
    content: $ti-icon-photo-off;
}
.#{$ti-icon-prefix}-physotherapist:before {
    content: $ti-icon-physotherapist;
}
.#{$ti-icon-prefix}-picture-in-picture:before {
    content: $ti-icon-picture-in-picture;
}
.#{$ti-icon-prefix}-picture-in-picture-off:before {
    content: $ti-icon-picture-in-picture-off;
}
.#{$ti-icon-prefix}-picture-in-picture-on:before {
    content: $ti-icon-picture-in-picture-on;
}
.#{$ti-icon-prefix}-pill:before {
    content: $ti-icon-pill;
}
.#{$ti-icon-prefix}-pin:before {
    content: $ti-icon-pin;
}
.#{$ti-icon-prefix}-pinned:before {
    content: $ti-icon-pinned;
}
.#{$ti-icon-prefix}-pinned-off:before {
    content: $ti-icon-pinned-off;
}
.#{$ti-icon-prefix}-pizza:before {
    content: $ti-icon-pizza;
}
.#{$ti-icon-prefix}-plane:before {
    content: $ti-icon-plane;
}
.#{$ti-icon-prefix}-plane-arrival:before {
    content: $ti-icon-plane-arrival;
}
.#{$ti-icon-prefix}-plane-departure:before {
    content: $ti-icon-plane-departure;
}
.#{$ti-icon-prefix}-planet:before {
    content: $ti-icon-planet;
}
.#{$ti-icon-prefix}-plant:before {
    content: $ti-icon-plant;
}
.#{$ti-icon-prefix}-plant-2:before {
    content: $ti-icon-plant-2;
}
.#{$ti-icon-prefix}-play-card:before {
    content: $ti-icon-play-card;
}
.#{$ti-icon-prefix}-player-pause:before {
    content: $ti-icon-player-pause;
}
.#{$ti-icon-prefix}-player-play:before {
    content: $ti-icon-player-play;
}
.#{$ti-icon-prefix}-player-record:before {
    content: $ti-icon-player-record;
}
.#{$ti-icon-prefix}-player-skip-back:before {
    content: $ti-icon-player-skip-back;
}
.#{$ti-icon-prefix}-player-skip-forward:before {
    content: $ti-icon-player-skip-forward;
}
.#{$ti-icon-prefix}-player-stop:before {
    content: $ti-icon-player-stop;
}
.#{$ti-icon-prefix}-player-track-next:before {
    content: $ti-icon-player-track-next;
}
.#{$ti-icon-prefix}-player-track-prev:before {
    content: $ti-icon-player-track-prev;
}
.#{$ti-icon-prefix}-playlist:before {
    content: $ti-icon-playlist;
}
.#{$ti-icon-prefix}-plug:before {
    content: $ti-icon-plug;
}
.#{$ti-icon-prefix}-plus:before {
    content: $ti-icon-plus;
}
.#{$ti-icon-prefix}-point:before {
    content: $ti-icon-point;
}
.#{$ti-icon-prefix}-pokeball:before {
    content: $ti-icon-pokeball;
}
.#{$ti-icon-prefix}-polaroid:before {
    content: $ti-icon-polaroid;
}
.#{$ti-icon-prefix}-pool:before {
    content: $ti-icon-pool;
}
.#{$ti-icon-prefix}-power:before {
    content: $ti-icon-power;
}
.#{$ti-icon-prefix}-pray:before {
    content: $ti-icon-pray;
}
.#{$ti-icon-prefix}-presentation:before {
    content: $ti-icon-presentation;
}
.#{$ti-icon-prefix}-presentation-analytics:before {
    content: $ti-icon-presentation-analytics;
}
.#{$ti-icon-prefix}-printer:before {
    content: $ti-icon-printer;
}
.#{$ti-icon-prefix}-prompt:before {
    content: $ti-icon-prompt;
}
.#{$ti-icon-prefix}-propeller:before {
    content: $ti-icon-propeller;
}
.#{$ti-icon-prefix}-puzzle:before {
    content: $ti-icon-puzzle;
}
.#{$ti-icon-prefix}-pyramid:before {
    content: $ti-icon-pyramid;
}
.#{$ti-icon-prefix}-qrcode:before {
    content: $ti-icon-qrcode;
}
.#{$ti-icon-prefix}-question-mark:before {
    content: $ti-icon-question-mark;
}
.#{$ti-icon-prefix}-radioactive:before {
    content: $ti-icon-radioactive;
}
.#{$ti-icon-prefix}-radius-bottom-left:before {
    content: $ti-icon-radius-bottom-left;
}
.#{$ti-icon-prefix}-radius-bottom-right:before {
    content: $ti-icon-radius-bottom-right;
}
.#{$ti-icon-prefix}-radius-top-left:before {
    content: $ti-icon-radius-top-left;
}
.#{$ti-icon-prefix}-radius-top-right:before {
    content: $ti-icon-radius-top-right;
}
.#{$ti-icon-prefix}-rainbow:before {
    content: $ti-icon-rainbow;
}
.#{$ti-icon-prefix}-receipt:before {
    content: $ti-icon-receipt;
}
.#{$ti-icon-prefix}-receipt-2:before {
    content: $ti-icon-receipt-2;
}
.#{$ti-icon-prefix}-receipt-off:before {
    content: $ti-icon-receipt-off;
}
.#{$ti-icon-prefix}-receipt-refund:before {
    content: $ti-icon-receipt-refund;
}
.#{$ti-icon-prefix}-receipt-tax:before {
    content: $ti-icon-receipt-tax;
}
.#{$ti-icon-prefix}-recharging:before {
    content: $ti-icon-recharging;
}
.#{$ti-icon-prefix}-record-mail:before {
    content: $ti-icon-record-mail;
}
.#{$ti-icon-prefix}-rectangle:before {
    content: $ti-icon-rectangle;
}
.#{$ti-icon-prefix}-rectangle-vertical:before {
    content: $ti-icon-rectangle-vertical;
}
.#{$ti-icon-prefix}-recycle:before {
    content: $ti-icon-recycle;
}
.#{$ti-icon-prefix}-refresh:before {
    content: $ti-icon-refresh;
}
.#{$ti-icon-prefix}-refresh-alert:before {
    content: $ti-icon-refresh-alert;
}
.#{$ti-icon-prefix}-registered:before {
    content: $ti-icon-registered;
}
.#{$ti-icon-prefix}-relation-many-to-many:before {
    content: $ti-icon-relation-many-to-many;
}
.#{$ti-icon-prefix}-relation-one-to-many:before {
    content: $ti-icon-relation-one-to-many;
}
.#{$ti-icon-prefix}-relation-one-to-one:before {
    content: $ti-icon-relation-one-to-one;
}
.#{$ti-icon-prefix}-repeat:before {
    content: $ti-icon-repeat;
}
.#{$ti-icon-prefix}-repeat-once:before {
    content: $ti-icon-repeat-once;
}
.#{$ti-icon-prefix}-replace:before {
    content: $ti-icon-replace;
}
.#{$ti-icon-prefix}-report:before {
    content: $ti-icon-report;
}
.#{$ti-icon-prefix}-report-analytics:before {
    content: $ti-icon-report-analytics;
}
.#{$ti-icon-prefix}-report-medical:before {
    content: $ti-icon-report-medical;
}
.#{$ti-icon-prefix}-report-money:before {
    content: $ti-icon-report-money;
}
.#{$ti-icon-prefix}-resize:before {
    content: $ti-icon-resize;
}
.#{$ti-icon-prefix}-ripple:before {
    content: $ti-icon-ripple;
}
.#{$ti-icon-prefix}-road-sign:before {
    content: $ti-icon-road-sign;
}
.#{$ti-icon-prefix}-rocket:before {
    content: $ti-icon-rocket;
}
.#{$ti-icon-prefix}-rotate:before {
    content: $ti-icon-rotate;
}
.#{$ti-icon-prefix}-rotate-2:before {
    content: $ti-icon-rotate-2;
}
.#{$ti-icon-prefix}-rotate-clockwise:before {
    content: $ti-icon-rotate-clockwise;
}
.#{$ti-icon-prefix}-rotate-clockwise-2:before {
    content: $ti-icon-rotate-clockwise-2;
}
.#{$ti-icon-prefix}-rotate-rectangle:before {
    content: $ti-icon-rotate-rectangle;
}
.#{$ti-icon-prefix}-route:before {
    content: $ti-icon-route;
}
.#{$ti-icon-prefix}-router:before {
    content: $ti-icon-router;
}
.#{$ti-icon-prefix}-row-insert-bottom:before {
    content: $ti-icon-row-insert-bottom;
}
.#{$ti-icon-prefix}-row-insert-top:before {
    content: $ti-icon-row-insert-top;
}
.#{$ti-icon-prefix}-rss:before {
    content: $ti-icon-rss;
}
.#{$ti-icon-prefix}-ruler:before {
    content: $ti-icon-ruler;
}
.#{$ti-icon-prefix}-ruler-2:before {
    content: $ti-icon-ruler-2;
}
.#{$ti-icon-prefix}-run:before {
    content: $ti-icon-run;
}
.#{$ti-icon-prefix}-sailboat:before {
    content: $ti-icon-sailboat;
}
.#{$ti-icon-prefix}-satellite:before {
    content: $ti-icon-satellite;
}
.#{$ti-icon-prefix}-scale:before {
    content: $ti-icon-scale;
}
.#{$ti-icon-prefix}-scan:before {
    content: $ti-icon-scan;
}
.#{$ti-icon-prefix}-school:before {
    content: $ti-icon-school;
}
.#{$ti-icon-prefix}-scissors:before {
    content: $ti-icon-scissors;
}
.#{$ti-icon-prefix}-scooter:before {
    content: $ti-icon-scooter;
}
.#{$ti-icon-prefix}-scooter-electric:before {
    content: $ti-icon-scooter-electric;
}
.#{$ti-icon-prefix}-screen-share:before {
    content: $ti-icon-screen-share;
}
.#{$ti-icon-prefix}-screen-share-off:before {
    content: $ti-icon-screen-share-off;
}
.#{$ti-icon-prefix}-scuba-mask:before {
    content: $ti-icon-scuba-mask;
}
.#{$ti-icon-prefix}-search:before {
    content: $ti-icon-search;
}
.#{$ti-icon-prefix}-section:before {
    content: $ti-icon-section;
}
.#{$ti-icon-prefix}-seeding:before {
    content: $ti-icon-seeding;
}
.#{$ti-icon-prefix}-select:before {
    content: $ti-icon-select;
}
.#{$ti-icon-prefix}-selector:before {
    content: $ti-icon-selector;
}
.#{$ti-icon-prefix}-send:before {
    content: $ti-icon-send;
}
.#{$ti-icon-prefix}-separator:before {
    content: $ti-icon-separator;
}
.#{$ti-icon-prefix}-separator-horizontal:before {
    content: $ti-icon-separator-horizontal;
}
.#{$ti-icon-prefix}-separator-vertical:before {
    content: $ti-icon-separator-vertical;
}
.#{$ti-icon-prefix}-server:before {
    content: $ti-icon-server;
}
.#{$ti-icon-prefix}-servicemark:before {
    content: $ti-icon-servicemark;
}
.#{$ti-icon-prefix}-settings:before {
    content: $ti-icon-settings;
}
.#{$ti-icon-prefix}-settings-automation:before {
    content: $ti-icon-settings-automation;
}
.#{$ti-icon-prefix}-shadow:before {
    content: $ti-icon-shadow;
}
.#{$ti-icon-prefix}-shadow-off:before {
    content: $ti-icon-shadow-off;
}
.#{$ti-icon-prefix}-shape:before {
    content: $ti-icon-shape;
}
.#{$ti-icon-prefix}-shape-2:before {
    content: $ti-icon-shape-2;
}
.#{$ti-icon-prefix}-shape-3:before {
    content: $ti-icon-shape-3;
}
.#{$ti-icon-prefix}-share:before {
    content: $ti-icon-share;
}
.#{$ti-icon-prefix}-shield:before {
    content: $ti-icon-shield;
}
.#{$ti-icon-prefix}-shield-check:before {
    content: $ti-icon-shield-check;
}
.#{$ti-icon-prefix}-shield-lock:before {
    content: $ti-icon-shield-lock;
}
.#{$ti-icon-prefix}-shield-off:before {
    content: $ti-icon-shield-off;
}
.#{$ti-icon-prefix}-shield-x:before {
    content: $ti-icon-shield-x;
}
.#{$ti-icon-prefix}-ship:before {
    content: $ti-icon-ship;
}
.#{$ti-icon-prefix}-shirt:before {
    content: $ti-icon-shirt;
}
.#{$ti-icon-prefix}-shopping-cart:before {
    content: $ti-icon-shopping-cart;
}
.#{$ti-icon-prefix}-shopping-cart-discount:before {
    content: $ti-icon-shopping-cart-discount;
}
.#{$ti-icon-prefix}-shopping-cart-off:before {
    content: $ti-icon-shopping-cart-off;
}
.#{$ti-icon-prefix}-shopping-cart-plus:before {
    content: $ti-icon-shopping-cart-plus;
}
.#{$ti-icon-prefix}-shopping-cart-x:before {
    content: $ti-icon-shopping-cart-x;
}
.#{$ti-icon-prefix}-shredder:before {
    content: $ti-icon-shredder;
}
.#{$ti-icon-prefix}-signature:before {
    content: $ti-icon-signature;
}
.#{$ti-icon-prefix}-sitemap:before {
    content: $ti-icon-sitemap;
}
.#{$ti-icon-prefix}-skateboard:before {
    content: $ti-icon-skateboard;
}
.#{$ti-icon-prefix}-slice:before {
    content: $ti-icon-slice;
}
.#{$ti-icon-prefix}-slideshow:before {
    content: $ti-icon-slideshow;
}
.#{$ti-icon-prefix}-smart-home:before {
    content: $ti-icon-smart-home;
}
.#{$ti-icon-prefix}-smoking:before {
    content: $ti-icon-smoking;
}
.#{$ti-icon-prefix}-smoking-no:before {
    content: $ti-icon-smoking-no;
}
.#{$ti-icon-prefix}-snowflake:before {
    content: $ti-icon-snowflake;
}
.#{$ti-icon-prefix}-soccer-field:before {
    content: $ti-icon-soccer-field;
}
.#{$ti-icon-prefix}-social:before {
    content: $ti-icon-social;
}
.#{$ti-icon-prefix}-sock:before {
    content: $ti-icon-sock;
}
.#{$ti-icon-prefix}-sort-ascending:before {
    content: $ti-icon-sort-ascending;
}
.#{$ti-icon-prefix}-sort-ascending-2:before {
    content: $ti-icon-sort-ascending-2;
}
.#{$ti-icon-prefix}-sort-descending:before {
    content: $ti-icon-sort-descending;
}
.#{$ti-icon-prefix}-sort-descending-2:before {
    content: $ti-icon-sort-descending-2;
}
.#{$ti-icon-prefix}-space:before {
    content: $ti-icon-space;
}
.#{$ti-icon-prefix}-speakerphone:before {
    content: $ti-icon-speakerphone;
}
.#{$ti-icon-prefix}-speedboat:before {
    content: $ti-icon-speedboat;
}
.#{$ti-icon-prefix}-sport-billard:before {
    content: $ti-icon-sport-billard;
}
.#{$ti-icon-prefix}-square:before {
    content: $ti-icon-square;
}
.#{$ti-icon-prefix}-square-0:before {
    content: $ti-icon-square-0;
}
.#{$ti-icon-prefix}-square-1:before {
    content: $ti-icon-square-1;
}
.#{$ti-icon-prefix}-square-2:before {
    content: $ti-icon-square-2;
}
.#{$ti-icon-prefix}-square-3:before {
    content: $ti-icon-square-3;
}
.#{$ti-icon-prefix}-square-4:before {
    content: $ti-icon-square-4;
}
.#{$ti-icon-prefix}-square-5:before {
    content: $ti-icon-square-5;
}
.#{$ti-icon-prefix}-square-6:before {
    content: $ti-icon-square-6;
}
.#{$ti-icon-prefix}-square-7:before {
    content: $ti-icon-square-7;
}
.#{$ti-icon-prefix}-square-8:before {
    content: $ti-icon-square-8;
}
.#{$ti-icon-prefix}-square-9:before {
    content: $ti-icon-square-9;
}
.#{$ti-icon-prefix}-square-check:before {
    content: $ti-icon-square-check;
}
.#{$ti-icon-prefix}-square-dot:before {
    content: $ti-icon-square-dot;
}
.#{$ti-icon-prefix}-square-forbid:before {
    content: $ti-icon-square-forbid;
}
.#{$ti-icon-prefix}-square-forbid-2:before {
    content: $ti-icon-square-forbid-2;
}
.#{$ti-icon-prefix}-square-minus:before {
    content: $ti-icon-square-minus;
}
.#{$ti-icon-prefix}-square-off:before {
    content: $ti-icon-square-off;
}
.#{$ti-icon-prefix}-square-plus:before {
    content: $ti-icon-square-plus;
}
.#{$ti-icon-prefix}-square-root:before {
    content: $ti-icon-square-root;
}
.#{$ti-icon-prefix}-square-root-2:before {
    content: $ti-icon-square-root-2;
}
.#{$ti-icon-prefix}-square-rotated:before {
    content: $ti-icon-square-rotated;
}
.#{$ti-icon-prefix}-square-rotated-off:before {
    content: $ti-icon-square-rotated-off;
}
.#{$ti-icon-prefix}-square-toggle:before {
    content: $ti-icon-square-toggle;
}
.#{$ti-icon-prefix}-square-toggle-horizontal:before {
    content: $ti-icon-square-toggle-horizontal;
}
.#{$ti-icon-prefix}-square-x:before {
    content: $ti-icon-square-x;
}
.#{$ti-icon-prefix}-squares-diagonal:before {
    content: $ti-icon-squares-diagonal;
}
.#{$ti-icon-prefix}-squares-filled:before {
    content: $ti-icon-squares-filled;
}
.#{$ti-icon-prefix}-stack:before {
    content: $ti-icon-stack;
}
.#{$ti-icon-prefix}-stack-2:before {
    content: $ti-icon-stack-2;
}
.#{$ti-icon-prefix}-stairs:before {
    content: $ti-icon-stairs;
}
.#{$ti-icon-prefix}-stairs-down:before {
    content: $ti-icon-stairs-down;
}
.#{$ti-icon-prefix}-stairs-up:before {
    content: $ti-icon-stairs-up;
}
.#{$ti-icon-prefix}-star:before {
    content: $ti-icon-star;
}
.#{$ti-icon-prefix}-star-half:before {
    content: $ti-icon-star-half;
}
.#{$ti-icon-prefix}-star-off:before {
    content: $ti-icon-star-off;
}
.#{$ti-icon-prefix}-stars:before {
    content: $ti-icon-stars;
}
.#{$ti-icon-prefix}-steering-wheel:before {
    content: $ti-icon-steering-wheel;
}
.#{$ti-icon-prefix}-step-into:before {
    content: $ti-icon-step-into;
}
.#{$ti-icon-prefix}-step-out:before {
    content: $ti-icon-step-out;
}
.#{$ti-icon-prefix}-stethoscope:before {
    content: $ti-icon-stethoscope;
}
.#{$ti-icon-prefix}-sticker:before {
    content: $ti-icon-sticker;
}
.#{$ti-icon-prefix}-strikethrough:before {
    content: $ti-icon-strikethrough;
}
.#{$ti-icon-prefix}-submarine:before {
    content: $ti-icon-submarine;
}
.#{$ti-icon-prefix}-subscript:before {
    content: $ti-icon-subscript;
}
.#{$ti-icon-prefix}-subtask:before {
    content: $ti-icon-subtask;
}
.#{$ti-icon-prefix}-sum:before {
    content: $ti-icon-sum;
}
.#{$ti-icon-prefix}-sun:before {
    content: $ti-icon-sun;
}
.#{$ti-icon-prefix}-sun-off:before {
    content: $ti-icon-sun-off;
}
.#{$ti-icon-prefix}-sunset:before {
    content: $ti-icon-sunset;
}
.#{$ti-icon-prefix}-sunshine:before {
    content: $ti-icon-sunshine;
}
.#{$ti-icon-prefix}-superscript:before {
    content: $ti-icon-superscript;
}
.#{$ti-icon-prefix}-swimming:before {
    content: $ti-icon-swimming;
}
.#{$ti-icon-prefix}-switch:before {
    content: $ti-icon-switch;
}
.#{$ti-icon-prefix}-switch-2:before {
    content: $ti-icon-switch-2;
}
.#{$ti-icon-prefix}-switch-3:before {
    content: $ti-icon-switch-3;
}
.#{$ti-icon-prefix}-switch-horizontal:before {
    content: $ti-icon-switch-horizontal;
}
.#{$ti-icon-prefix}-switch-vertical:before {
    content: $ti-icon-switch-vertical;
}
.#{$ti-icon-prefix}-table:before {
    content: $ti-icon-table;
}
.#{$ti-icon-prefix}-table-export:before {
    content: $ti-icon-table-export;
}
.#{$ti-icon-prefix}-table-import:before {
    content: $ti-icon-table-import;
}
.#{$ti-icon-prefix}-table-off:before {
    content: $ti-icon-table-off;
}
.#{$ti-icon-prefix}-tag:before {
    content: $ti-icon-tag;
}
.#{$ti-icon-prefix}-tallymark-1:before {
    content: $ti-icon-tallymark-1;
}
.#{$ti-icon-prefix}-tallymark-2:before {
    content: $ti-icon-tallymark-2;
}
.#{$ti-icon-prefix}-tallymark-3:before {
    content: $ti-icon-tallymark-3;
}
.#{$ti-icon-prefix}-tallymark-4:before {
    content: $ti-icon-tallymark-4;
}
.#{$ti-icon-prefix}-tallymarks:before {
    content: $ti-icon-tallymarks;
}
.#{$ti-icon-prefix}-tank:before {
    content: $ti-icon-tank;
}
.#{$ti-icon-prefix}-target:before {
    content: $ti-icon-target;
}
.#{$ti-icon-prefix}-temperature:before {
    content: $ti-icon-temperature;
}
.#{$ti-icon-prefix}-temperature-celsius:before {
    content: $ti-icon-temperature-celsius;
}
.#{$ti-icon-prefix}-temperature-fahrenheit:before {
    content: $ti-icon-temperature-fahrenheit;
}
.#{$ti-icon-prefix}-temperature-minus:before {
    content: $ti-icon-temperature-minus;
}
.#{$ti-icon-prefix}-temperature-plus:before {
    content: $ti-icon-temperature-plus;
}
.#{$ti-icon-prefix}-template:before {
    content: $ti-icon-template;
}
.#{$ti-icon-prefix}-tent:before {
    content: $ti-icon-tent;
}
.#{$ti-icon-prefix}-terminal:before {
    content: $ti-icon-terminal;
}
.#{$ti-icon-prefix}-terminal-2:before {
    content: $ti-icon-terminal-2;
}
.#{$ti-icon-prefix}-test-pipe:before {
    content: $ti-icon-test-pipe;
}
.#{$ti-icon-prefix}-text-direction-ltr:before {
    content: $ti-icon-text-direction-ltr;
}
.#{$ti-icon-prefix}-text-direction-rtl:before {
    content: $ti-icon-text-direction-rtl;
}
.#{$ti-icon-prefix}-text-wrap:before {
    content: $ti-icon-text-wrap;
}
.#{$ti-icon-prefix}-text-wrap-disabled:before {
    content: $ti-icon-text-wrap-disabled;
}
.#{$ti-icon-prefix}-thumb-down:before {
    content: $ti-icon-thumb-down;
}
.#{$ti-icon-prefix}-thumb-up:before {
    content: $ti-icon-thumb-up;
}
.#{$ti-icon-prefix}-ticket:before {
    content: $ti-icon-ticket;
}
.#{$ti-icon-prefix}-tilt-shift:before {
    content: $ti-icon-tilt-shift;
}
.#{$ti-icon-prefix}-tir:before {
    content: $ti-icon-tir;
}
.#{$ti-icon-prefix}-toggle-left:before {
    content: $ti-icon-toggle-left;
}
.#{$ti-icon-prefix}-toggle-right:before {
    content: $ti-icon-toggle-right;
}
.#{$ti-icon-prefix}-tool:before {
    content: $ti-icon-tool;
}
.#{$ti-icon-prefix}-tools:before {
    content: $ti-icon-tools;
}
.#{$ti-icon-prefix}-tools-kitchen:before {
    content: $ti-icon-tools-kitchen;
}
.#{$ti-icon-prefix}-tools-kitchen-2:before {
    content: $ti-icon-tools-kitchen-2;
}
.#{$ti-icon-prefix}-tornado:before {
    content: $ti-icon-tornado;
}
.#{$ti-icon-prefix}-tournament:before {
    content: $ti-icon-tournament;
}
.#{$ti-icon-prefix}-track:before {
    content: $ti-icon-track;
}
.#{$ti-icon-prefix}-tractor:before {
    content: $ti-icon-tractor;
}
.#{$ti-icon-prefix}-trademark:before {
    content: $ti-icon-trademark;
}
.#{$ti-icon-prefix}-traffic-cone:before {
    content: $ti-icon-traffic-cone;
}
.#{$ti-icon-prefix}-traffic-lights:before {
    content: $ti-icon-traffic-lights;
}
.#{$ti-icon-prefix}-train:before {
    content: $ti-icon-train;
}
.#{$ti-icon-prefix}-trash:before {
    content: $ti-icon-trash;
}
.#{$ti-icon-prefix}-trash-off:before {
    content: $ti-icon-trash-off;
}
.#{$ti-icon-prefix}-tree:before {
    content: $ti-icon-tree;
}
.#{$ti-icon-prefix}-trees:before {
    content: $ti-icon-trees;
}
.#{$ti-icon-prefix}-trending-down:before {
    content: $ti-icon-trending-down;
}
.#{$ti-icon-prefix}-trending-down-2:before {
    content: $ti-icon-trending-down-2;
}
.#{$ti-icon-prefix}-trending-down-3:before {
    content: $ti-icon-trending-down-3;
}
.#{$ti-icon-prefix}-trending-up:before {
    content: $ti-icon-trending-up;
}
.#{$ti-icon-prefix}-trending-up-2:before {
    content: $ti-icon-trending-up-2;
}
.#{$ti-icon-prefix}-trending-up-3:before {
    content: $ti-icon-trending-up-3;
}
.#{$ti-icon-prefix}-triangle:before {
    content: $ti-icon-triangle;
}
.#{$ti-icon-prefix}-triangle-off:before {
    content: $ti-icon-triangle-off;
}
.#{$ti-icon-prefix}-triangle-square-circle:before {
    content: $ti-icon-triangle-square-circle;
}
.#{$ti-icon-prefix}-trident:before {
    content: $ti-icon-trident;
}
.#{$ti-icon-prefix}-trophy:before {
    content: $ti-icon-trophy;
}
.#{$ti-icon-prefix}-truck:before {
    content: $ti-icon-truck;
}
.#{$ti-icon-prefix}-truck-delivery:before {
    content: $ti-icon-truck-delivery;
}
.#{$ti-icon-prefix}-truck-off:before {
    content: $ti-icon-truck-off;
}
.#{$ti-icon-prefix}-truck-return:before {
    content: $ti-icon-truck-return;
}
.#{$ti-icon-prefix}-typography:before {
    content: $ti-icon-typography;
}
.#{$ti-icon-prefix}-umbrella:before {
    content: $ti-icon-umbrella;
}
.#{$ti-icon-prefix}-underline:before {
    content: $ti-icon-underline;
}
.#{$ti-icon-prefix}-unlink:before {
    content: $ti-icon-unlink;
}
.#{$ti-icon-prefix}-upload:before {
    content: $ti-icon-upload;
}
.#{$ti-icon-prefix}-urgent:before {
    content: $ti-icon-urgent;
}
.#{$ti-icon-prefix}-user:before {
    content: $ti-icon-user;
}
.#{$ti-icon-prefix}-user-check:before {
    content: $ti-icon-user-check;
}
.#{$ti-icon-prefix}-user-exclamation:before {
    content: $ti-icon-user-exclamation;
}
.#{$ti-icon-prefix}-user-minus:before {
    content: $ti-icon-user-minus;
}
.#{$ti-icon-prefix}-user-off:before {
    content: $ti-icon-user-off;
}
.#{$ti-icon-prefix}-user-plus:before {
    content: $ti-icon-user-plus;
}
.#{$ti-icon-prefix}-user-x:before {
    content: $ti-icon-user-x;
}
.#{$ti-icon-prefix}-users:before {
    content: $ti-icon-users;
}
.#{$ti-icon-prefix}-vaccine:before {
    content: $ti-icon-vaccine;
}
.#{$ti-icon-prefix}-variable:before {
    content: $ti-icon-variable;
}
.#{$ti-icon-prefix}-vector:before {
    content: $ti-icon-vector;
}
.#{$ti-icon-prefix}-vector-beizer:before {
    content: $ti-icon-vector-beizer;
}
.#{$ti-icon-prefix}-vector-beizer-2:before {
    content: $ti-icon-vector-beizer-2;
}
.#{$ti-icon-prefix}-vector-triangle:before {
    content: $ti-icon-vector-triangle;
}
.#{$ti-icon-prefix}-venus:before {
    content: $ti-icon-venus;
}
.#{$ti-icon-prefix}-versions:before {
    content: $ti-icon-versions;
}
.#{$ti-icon-prefix}-video:before {
    content: $ti-icon-video;
}
.#{$ti-icon-prefix}-video-minus:before {
    content: $ti-icon-video-minus;
}
.#{$ti-icon-prefix}-video-off:before {
    content: $ti-icon-video-off;
}
.#{$ti-icon-prefix}-video-plus:before {
    content: $ti-icon-video-plus;
}
.#{$ti-icon-prefix}-view-360:before {
    content: $ti-icon-view-360;
}
.#{$ti-icon-prefix}-viewfinder:before {
    content: $ti-icon-viewfinder;
}
.#{$ti-icon-prefix}-viewport-narrow:before {
    content: $ti-icon-viewport-narrow;
}
.#{$ti-icon-prefix}-viewport-wide:before {
    content: $ti-icon-viewport-wide;
}
.#{$ti-icon-prefix}-virus:before {
    content: $ti-icon-virus;
}
.#{$ti-icon-prefix}-virus-off:before {
    content: $ti-icon-virus-off;
}
.#{$ti-icon-prefix}-virus-search:before {
    content: $ti-icon-virus-search;
}
.#{$ti-icon-prefix}-volume:before {
    content: $ti-icon-volume;
}
.#{$ti-icon-prefix}-volume-2:before {
    content: $ti-icon-volume-2;
}
.#{$ti-icon-prefix}-volume-3:before {
    content: $ti-icon-volume-3;
}
.#{$ti-icon-prefix}-walk:before {
    content: $ti-icon-walk;
}
.#{$ti-icon-prefix}-wallet:before {
    content: $ti-icon-wallet;
}
.#{$ti-icon-prefix}-wand:before {
    content: $ti-icon-wand;
}
.#{$ti-icon-prefix}-wave-saw-tool:before {
    content: $ti-icon-wave-saw-tool;
}
.#{$ti-icon-prefix}-wave-sine:before {
    content: $ti-icon-wave-sine;
}
.#{$ti-icon-prefix}-wave-square:before {
    content: $ti-icon-wave-square;
}
.#{$ti-icon-prefix}-wifi:before {
    content: $ti-icon-wifi;
}
.#{$ti-icon-prefix}-wifi-0:before {
    content: $ti-icon-wifi-0;
}
.#{$ti-icon-prefix}-wifi-1:before {
    content: $ti-icon-wifi-1;
}
.#{$ti-icon-prefix}-wifi-2:before {
    content: $ti-icon-wifi-2;
}
.#{$ti-icon-prefix}-wifi-off:before {
    content: $ti-icon-wifi-off;
}
.#{$ti-icon-prefix}-wind:before {
    content: $ti-icon-wind;
}
.#{$ti-icon-prefix}-windmill:before {
    content: $ti-icon-windmill;
}
.#{$ti-icon-prefix}-window:before {
    content: $ti-icon-window;
}
.#{$ti-icon-prefix}-wiper:before {
    content: $ti-icon-wiper;
}
.#{$ti-icon-prefix}-wiper-wash:before {
    content: $ti-icon-wiper-wash;
}
.#{$ti-icon-prefix}-woman:before {
    content: $ti-icon-woman;
}
.#{$ti-icon-prefix}-world:before {
    content: $ti-icon-world;
}
.#{$ti-icon-prefix}-world-latitude:before {
    content: $ti-icon-world-latitude;
}
.#{$ti-icon-prefix}-world-longitude:before {
    content: $ti-icon-world-longitude;
}
.#{$ti-icon-prefix}-wrecking-ball:before {
    content: $ti-icon-wrecking-ball;
}
.#{$ti-icon-prefix}-writing:before {
    content: $ti-icon-writing;
}
.#{$ti-icon-prefix}-writing-sign:before {
    content: $ti-icon-writing-sign;
}
.#{$ti-icon-prefix}-x:before {
    content: $ti-icon-x;
}
.#{$ti-icon-prefix}-yin-yang:before {
    content: $ti-icon-yin-yang;
}
.#{$ti-icon-prefix}-zodiac-aquarius:before {
    content: $ti-icon-zodiac-aquarius;
}
.#{$ti-icon-prefix}-zodiac-aries:before {
    content: $ti-icon-zodiac-aries;
}
.#{$ti-icon-prefix}-zodiac-cancer:before {
    content: $ti-icon-zodiac-cancer;
}
.#{$ti-icon-prefix}-zodiac-capricorn:before {
    content: $ti-icon-zodiac-capricorn;
}
.#{$ti-icon-prefix}-zodiac-gemini:before {
    content: $ti-icon-zodiac-gemini;
}
.#{$ti-icon-prefix}-zodiac-leo:before {
    content: $ti-icon-zodiac-leo;
}
.#{$ti-icon-prefix}-zodiac-libra:before {
    content: $ti-icon-zodiac-libra;
}
.#{$ti-icon-prefix}-zodiac-pisces:before {
    content: $ti-icon-zodiac-pisces;
}
.#{$ti-icon-prefix}-zodiac-sagittarius:before {
    content: $ti-icon-zodiac-sagittarius;
}
.#{$ti-icon-prefix}-zodiac-scorpio:before {
    content: $ti-icon-zodiac-scorpio;
}
.#{$ti-icon-prefix}-zodiac-taurus:before {
    content: $ti-icon-zodiac-taurus;
}
.#{$ti-icon-prefix}-zodiac-virgo:before {
    content: $ti-icon-zodiac-virgo;
}
.#{$ti-icon-prefix}-zoom-cancel:before {
    content: $ti-icon-zoom-cancel;
}
.#{$ti-icon-prefix}-zoom-check:before {
    content: $ti-icon-zoom-check;
}
.#{$ti-icon-prefix}-zoom-in:before {
    content: $ti-icon-zoom-in;
}
.#{$ti-icon-prefix}-zoom-money:before {
    content: $ti-icon-zoom-money;
}
.#{$ti-icon-prefix}-zoom-out:before {
    content: $ti-icon-zoom-out;
}
.#{$ti-icon-prefix}-zoom-question:before {
    content: $ti-icon-zoom-question;
}
